import React from 'react';
import { Divider } from '@material-ui/core';

import Notes from './Notes/Notes';
import Tasks from './Tasks/Tasks';
import Proposals from './Proposals/Proposals';
import styles from './ActivityTab.module.css';
import Files from './Files';
import Contact from '../../../../../types/contact';

interface IActivityTabProps {
  contactId: Contact['uuid'];
}

const ActivityTab: React.FC<IActivityTabProps> = ({ contactId }) => {
  return (
    <>
      <Notes />
      <Divider className={styles.divider} />
      <Tasks />
      <Divider className={styles.divider} />
      <Proposals />
      <Files contactId={contactId} />
    </>
  );
};
export default ActivityTab;
