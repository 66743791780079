import useTaskDrawer from 'src/features/tasks/TaskDrawer/useTaskDrawer';
import { useTasksLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

const useTaskViewModel = () => {
  const { openCreateForm } = useTaskDrawer();
  const { checkLimits } = useTasksLimits();
  const handleOpenForm = (defaultValues) => {
    checkLimits() && openCreateForm({ defaultValues });
  };

  return {
    handleOpenForm,
  };
};
export default useTaskViewModel;
