import { Paper, InputBase } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import styles from '../ActivityTab.module.css';
import Button from 'components/UI/Buttons/BaseButton';
const Form = ({
  type = 'create',
  placeholder = '',
  onSuccess,
  onClose,
  open,
  setOpen,
  initialValue = '',
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const { t } = useTranslation('common');
  useEffect(() => {
    !open && setValue('');
  }, [open]);
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleClose = () => {
    onClose();
    setValue('');
    setOpen(false);
  };
  const handleSucceed = () => {
    onSuccess(value);
    setValue('');
    setOpen(false);
  };
  if (open) {
    return (
      <Paper
        className={clsx({
          [styles.formWrapper]: true,
          [styles.newForm]: !initialValue.length,
        })}
      >
        <InputBase
          classes={{ root: styles.formInput }}
          multiline
          placeholder={placeholder}
          onChange={handleChange}
          // eslint-disable-next-line
          autoFocus
          value={value}
          inputProps={{ 'data-hj-allow': '' }}
        />
        <div className={styles.formActions}>
          <Button variant={'text'} className={styles.closeButton} onClick={handleClose}>
            {t('duplicates:Cancel_action')}
          </Button>

          <Button className={styles.successButton} onClick={handleSucceed}>
            {type == 'create' ? t('action.create') : t('duplicates:Save_action')}
          </Button>
        </div>
      </Paper>
    );
  }
  return <></>;
};
export default Form;
