import React, { forwardRef } from 'react';
import clsx from 'clsx';

import allowedFilesTypes from '../../../../../utils/constants/allowedFilesTypes';
import useFilesInputViewModel from './useFilesInputViewModel';
import styles from './styles.module.scss';

interface IFilesInputProps {
  className?: string;
  hidden?: boolean;
  onFilesAddedToQueue?: () => void;
}

const FilesInput = forwardRef<HTMLInputElement, IFilesInputProps>(
  ({ className, hidden, onFilesAddedToQueue }, ref) => {
    const { handleInputFilesSelect } = useFilesInputViewModel(onFilesAddedToQueue);

    return (
      <input
        className={clsx(styles.Input, className)}
        accept={allowedFilesTypes}
        type="file"
        hidden={hidden}
        ref={ref}
        multiple
        onChange={handleInputFilesSelect}
      />
    );
  }
);

FilesInput.displayName = 'FilesInput';

export default FilesInput;
