import { useProposalDrawer } from 'src/features/proposals/ProposalDrawer';

const useProposalsViewModel = () => {
  const { openCreateForm, close } = useProposalDrawer();
  const handleCreateForm = (props) => {
    openCreateForm({ onSuccess: close, ...props });
  };
  return {
    handleCreateForm,
  };
};
export default useProposalsViewModel;
