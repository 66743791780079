import clsx from 'clsx';

import Avatar from '../../Avatar';
import Badges from './Badges';
import styles from '../ContactCard.module.css';

export default function Details(
  props: {
    thumbnail?: string;
    name?: string;
    nameEndAdornment?: string | React.ReactNode;
    phoneNumber?: string;
    jobTitle?: string;
    badges: Array<{ title: string; color: string }>;
    avatarClassName?: string;
    nameClassName?: string;
    phoneNumberClassName?: string;
    classNameTags?: string;
    isSpammer?: boolean;
    children?: React.ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>
) {
  const {
    thumbnail,
    name,
    nameEndAdornment,
    phoneNumber,
    jobTitle,
    badges,
    isSpammer = false,
    className,
    avatarClassName,
    nameClassName,
    classNameTags,
    phoneNumberClassName,
    children,
    ...rest
  } = props;

  return (
    <div className={clsx(styles.detailsContainer, className)} {...rest}>
      {isSpammer ? (
        <Avatar className={clsx(styles.Avatar, avatarClassName, styles.AvatarSpammer)} />
      ) : (
        <Avatar
          className={clsx(styles.Avatar, avatarClassName)}
          thumbnail={thumbnail}
          name={name}
        />
      )}

      <div className={styles.textContent}>
        <div
          className={clsx(
            styles.titlePrimary,
            nameClassName,
            isSpammer && styles.spammer,
            !name && phoneNumberClassName
          )}
        >
          {name || phoneNumber}
          {!!nameEndAdornment && nameEndAdornment}
        </div>
        {!!name && (
          <div className={clsx(styles.titleSecondary, phoneNumberClassName)}>
            {jobTitle || phoneNumber}
          </div>
        )}
        {!!badges && <Badges className={classNameTags} list={badges} />}
        {children}
      </div>
    </div>
  );
}
