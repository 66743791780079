import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import TaskOption from 'icons/tasks-black.svg';
import useTaskViewModel from '../useTaskViewModel';

interface IProps {
  contactId?: string;
  onClick: () => void;
}
const AddTaskMenuOption: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { handleOpenForm } = useTaskViewModel();
  return (
    <>
      <button
        className={styles.AddTaskOption}
        onClick={() => {
          props.onClick();
          handleOpenForm({ contactId: props.contactId });
        }}
      >
        <TaskOption />
        <span>{t('contact-details:actions.more.add_task')}</span>
      </button>
    </>
  );
};
export default AddTaskMenuOption;
