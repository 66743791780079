import { useTranslation } from 'next-i18next';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import clsx from 'clsx';

import { FormValues } from '../types';
import Select from 'components/UI/Inputs/Select';
import styles from 'styles/addContact/addContacts.module.css';
import styles2 from 'styles/contactDetails/contactDetails.module.scss';
import Button from 'components/UI/Buttons/BaseButton';
import DatePicker from 'components/UI/Inputs/DatePicker';
import { FormArrayNames, FormTypes, EventFormSelectOptions, getFieldName } from './formData';

export default function EventsInput({
  updateView = () => {
    return;
  },
}) {
  const { t } = useTranslation('add-contact-page');
  const { control } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray<FormValues, FormArrayNames.DATES>({
    control,
    name: FormArrayNames.DATES,
  });

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.id} className={styles2.eventField}>
            <Controller
              name={getFieldName(FormTypes.EVENT, index, true)}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    className={styles2.select}
                    variant="standard"
                    defaultValue={'custom'}
                    classes={{ root: styles2.formSelect }}
                    menuItemClassName={styles2.formSelectItem}
                    options={[
                      { value: EventFormSelectOptions.BIRTHDAY, text: t('birthday') },
                      { value: EventFormSelectOptions.ANNIVERSARY, text: t('anniversary') },
                      { value: EventFormSelectOptions.OTHER, text: t('duplicates:Other_label') },
                    ]}
                  />
                );
              }}
            />
            <Controller
              name={getFieldName(FormTypes.EVENT, index)}
              control={control}
              render={({ field }) => (
                <div className={styles2.formField}>
                  <DatePicker updateView={updateView} {...field} />
                  <Button
                    className={clsx(styles2.formDeleteButton)}
                    variant="text"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    {t('duplicates:Delete_action')}
                  </Button>
                </div>
              )}
            />
          </div>
        );
      })}
      <button
        className={styles.addFieldButton}
        type="button"
        onClick={() => {
          append({ event: new Date(), type: EventFormSelectOptions.BIRTHDAY });
        }}
      >
        {fields.length ? t('addAnotherEvent') : t('contact-details:fields.event.add')}
      </button>
    </div>
  );
}
