import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import QuoteOption from 'icons/quote-black.svg';
import useProposalsViewModel from '../useProposalsViewModel';

interface IProps {
  contactId?: string;
  type?: string;
  onClick: () => void;
}
const AddProposalMenuOption: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { handleCreateForm } = useProposalsViewModel();
  return (
    <>
      <button
        className={styles.AddProposalOption}
        onClick={() => {
          props.onClick();
          handleCreateForm({
            shouldPinContact: !!props.contactId,
            type: props.type,
            defaultValues: { contactId: props.contactId },
          });
        }}
      >
        <QuoteOption />
        <span>{t('contact-details:actions.more.add_quote')}</span>
      </button>
    </>
  );
};
export default AddProposalMenuOption;
