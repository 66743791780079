import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import FileBC from '../../../domain/FileBC';
import Contact from '../../../../../../types/contact';
import FilesContext from '../../../domain/context/FilesContext';

type UseFilesListViewModel = ({
  setFilesCount,
  currentContactUuid,
}: {
  setFilesCount: Dispatch<SetStateAction<number>>;
  currentContactUuid: Contact['uuid'];
}) => {
  filesList: FileBC[];
  itemRef: MutableRefObject<HTMLDivElement>;
};

const useFilesListViewModel: UseFilesListViewModel = ({ setFilesCount, currentContactUuid }) => {
  const { commonFilesList } = useContext(FilesContext);
  const itemRef = useRef<HTMLDivElement>(null);
  const [uiFilesList, setUiFilesList] = useState<FileBC[]>([]);

  useEffect(() => {
    setUiFilesList(commonFilesList.filter((file) => file.itemUuid === currentContactUuid));
  }, [commonFilesList, currentContactUuid]);

  useEffect(() => {
    setFilesCount(uiFilesList.length);
  }, [uiFilesList]);

  return {
    filesList: uiFilesList,
    itemRef,
  };
};

export default useFilesListViewModel;
