import clsx from 'clsx';
import styles from './styles/Input.module.css';
const DateInput = ({ value, onClick, openDatePicker }) => {
  return (
    <button
      className={clsx({
        [styles.input]: true,
        [styles.hiddenInput]: openDatePicker,
      })}
      onClick={onClick}
    >
      {value}
    </button>
  );
};
export default DateInput;
