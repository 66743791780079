import { useAppDispatch } from 'src/app/hooks';
import { setOpenFormNote } from 'slices/notesSlice';
import { useNotesLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

const useNotesViewModel = () => {
  const dispatch = useAppDispatch();
  const { checkLimits } = useNotesLimits();
  const handleOpenForm = () => {
    checkLimits() && dispatch(setOpenFormNote(true));
  };
  return {
    handleOpenForm,
  };
};

export default useNotesViewModel;
