import Link from 'next/link';
import PhoneIcon from '@material-ui/icons/Phone';

import styles from '../ContactCard.module.css';

interface Actions {
  phoneNumber: string;
}

export default function Actions({ phoneNumber }: { phoneNumber: string }) {
  return (
    <div className={styles.actions}>
      <Link href={`tel:${phoneNumber}`}>
        <a>
          <PhoneIcon />
        </a>
      </Link>
    </div>
  );
}
