import { trackEvent } from 'src/analytics/amplitude';
import { useAppDispatch } from '../../../app/hooks/useAppDispatch';
import { patchContactOptimistic } from '../../../slices/contactsSlice';

import useAssign from './useAssign';

export default function useAssignContact(type: string) {
  const dispatch = useAppDispatch();

  const assign = useAssign();

  const updateCallback = async (contactId: string, teamMemberEmail?: string) => {
    const data = {
      assigned_to: teamMemberEmail || null,
      updated_at: Math.floor(Date.now() / 1000),
    };
    trackEvent('Change_assign_to', { type });
    dispatch(patchContactOptimistic({ contactId, data })).unwrap();
  };

  return (contactId: string, teamMemberEmail?: string) => {
    return assign({
      updateCallback: updateCallback,
      entityId: contactId,
      teamMemberEmail,
    });
  };
}
