import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import LocalStorageKeys from '../../../enums/LocalStorageKeys';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../../../utils';

interface IProps {
  children: (isExpanded: boolean, setIsExpanded: React.Dispatch<boolean>) => React.ReactNode;
  columnKey:
    | LocalStorageKeys.IS_CALLS_COLUMN_EXPANDED
    | LocalStorageKeys.IS_CONTACTS_COLUMN_EXPANDED;
  className: string;
}

export let externalSetIsColumnExpanded;

const CallsContactsColumn: React.FC<IProps> = ({ children, className, columnKey }) => {
  const [isExpanded, setIsExpanded] = useState(getDataFromLocalStorage(columnKey));

  externalSetIsColumnExpanded = setIsExpanded;

  useEffect(() => {
    const savedState = getDataFromLocalStorage(columnKey);

    if (savedState !== undefined) {
      setIsExpanded(savedState);
    } else {
      setIsExpanded(true);
    }
  }, [columnKey]);

  useEffect(() => {
    setDataToLocalStorage(columnKey, isExpanded);
  }, [isExpanded, columnKey]);

  const onExpand = () => setIsExpanded(!isExpanded);

  return (
    <div
      className={clsx(styles.Column, className, {
        [styles.Collapsed]: !isExpanded,
      })}
    >
      <div
        onClick={onExpand}
        className={clsx(styles.SwitchButton, isExpanded && styles.ButtonExpanded)}
      />
      {children(isExpanded, setIsExpanded)}
    </div>
  );
};

export default CallsContactsColumn;
