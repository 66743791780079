import { useTranslation } from 'next-i18next';
import { useState, useRef } from 'react';
import { useSnackbar } from 'notistack';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { setField, updateContactById } from 'slices/contactsSlice';
import axios from '../../app/axios';
import thirdParyAxios from '../../app/thirdPartyAxios';
import { endpoints } from '../../utils/constants';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import { useAppSelector, useAppDispatch } from 'src/app/hooks';
import { selectCurrentContact } from 'slices/contactsSlice';
import contactService from '../../services/contacts/contactService';
import Avatar from 'components/UI/Avatar';
import styles from '../../styles/contactDetails/contactDetails.module.scss';
import CameraIcon from '../../../public/images/icons/photo-camera.svg';
import RequestHeaders from '../../enums/RequestHeaders';
import ContentTypes from '../../enums/ContentTypes';

interface ContactPhotoProps {
  imageUrl?: string;
}

const ContactPhoto: React.FC<ContactPhotoProps> = ({ imageUrl }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { t } = useTranslation('contact-details');
  const currentContact = useAppSelector(selectCurrentContact);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const inputFile = useRef(null);
  const [file, setFile] = useState(currentContact.thumbnail);
  const avatarUrl = imageUrl ? imageUrl : file;
  const contactName = contactService.getContactName(currentContact);

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleUploadPhotoAction = () => {
    handleAvatarMenuClosed();
    inputFile.current.click();
  };
  const handleOnChangeFile = (event) => {
    const pathToFile = event.target.files[0];
    const file = URL.createObjectURL(pathToFile);
    setFile(file);
    inputFile.current.value = null;

    uploadPhoto(pathToFile);
  };
  const handleRemovePhotoAction = () => {
    handleAvatarMenuClosed();
    setFile(null);
    dispatch(
      setField({
        type: 'thumbnail',
        value: '',
      })
    );
    dispatch(updateContactById(currentContact.uuid));
  };
  const handleAvatarMenuClosed = () => {
    setAnchorEl(null);
  };

  async function uploadPhoto(file) {
    try {
      const uploadFileResponse = await (
        await axios.post(endpoints.media.uploadFile, {
          mime_type: 'image/jpeg',
        })
      ).data;

      const formData = new FormData();

      const paramsToSent = uploadFileResponse.post_params.params;
      for (const key in paramsToSent) {
        formData.append(key, paramsToSent[key]);
      }

      formData.append('file', file);

      await thirdParyAxios.post(uploadFileResponse.post_url, formData, {
        headers: {
          [RequestHeaders.CONTENT_TYPE]: ContentTypes.MULTIPART_FORM_DATA,
        },
      });

      await axios.post(
        endpoints.media.confirmUploadFile.replace('{uuid}', uploadFileResponse.uuid)
      );
      dispatch(
        setField({
          type: 'thumbnail',
          value: uploadFileResponse.file_url,
        })
      );
      dispatch(updateContactById(currentContact.uuid));
    } catch (error) {
      enqueueSnackbar({ message: t('error_uploading_photo'), variant: MessageType.Error });
      handleRemovePhotoAction();
    }
  }

  return (
    <>
      <div className={styles.image} onClick={handleAvatarClick}>
        <Avatar thumbnail={avatarUrl} size={60} className={styles.Avatar} name={contactName} />
        <div className={styles.hoverImage}>
          <CameraIcon />
        </div>
      </div>
      <Menu
        PopoverClasses={{ paper: styles.avatarMenu }}
        id="avatar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleAvatarMenuClosed}
      >
        <MenuItem classes={{ root: styles.avatarMenuItem }} onClick={handleUploadPhotoAction}>
          {t('actions.uploadImage')}
          <input
            type="file"
            id="file"
            style={{ display: 'none' }}
            onChange={handleOnChangeFile}
            accept="image/*"
            ref={inputFile}
            data-hj-allow={''}
          />
        </MenuItem>
        {currentContact.thumbnail && (
          <MenuItem classes={{ root: styles.avatarMenuItem }} onClick={handleRemovePhotoAction}>
            {t('actions.removeImage')}
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
export default ContactPhoto;
