import { useAppDispatch, useAppStore } from '../../../../app/hooks';
import { useProposalDrawer } from '../../../proposals/ProposalDrawer';
import { actions as boardActions, selectBoard } from '../../../../slices/boardSlice';
import { selectContactsMap } from 'slices/contactsSlice';
import { hideCallsBatch } from 'slices/callsSlice';
import { withErrorSnackbar } from 'src/services/api/withErrorSnackbar';
import { useSnackbar } from 'notistack';

function useCreateProposalFromBoardItem(type: string) {
  const { openCreateForm, close } = useProposalDrawer();
  const dispatch = useAppDispatch();
  const store = useAppStore();
  const { enqueueSnackbar } = useSnackbar();

  return (itemUuid: string, colUuid: string) => {
    const board = selectBoard(store.getState());
    const item = board.columns
      .find((colItem) => colItem.id === colUuid)
      .items.find((item) => item.uuid === itemUuid);
    const handleSuccess = () => {
      withErrorSnackbar({
        onTry: async () => {
          const contactsMap = selectContactsMap(store.getState());
          const contact = contactsMap[item.typeUuid];
          const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
          dispatch(hideCallsBatch(contactPhones));
        },
        enqueueSnackbar,
      });

      dispatch(
        boardActions.deleteItems({
          columnUuid: colUuid,
          itemsUuids: [itemUuid],
        })
      );
      close();
    };

    openCreateForm({
      defaultValues: { contactId: item.typeUuid },
      onSuccess: handleSuccess,
      shouldPinContact: true,
      type,
    });
  };
}

export default useCreateProposalFromBoardItem;
