import { useState, ChangeEventHandler, FormEventHandler } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

//TODO: change it!
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';

import { itemColors } from '../../../utils/constants';
import PaletteForm from '../PaletteForm/PaletteForm';

import styles from './CreateTagForm.module.css';

export interface CreateTagFormProps {
  defaultName?: string;
  className?: string;
  paletteZindex?: number;
  onSubmit: (name: string, color: string) => void;
  onCancel: () => void;
}

export const CreateTagForm = ({
  defaultName = '',
  onSubmit,
  onCancel,
  className,
  paletteZindex,
}: CreateTagFormProps) => {
  const [name, setName] = useState(defaultName);
  const [choosedColor, setChoosedColor] = useState(itemColors[0]);

  const { t } = useTranslation(['common', 'contacts-page']);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value);
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    onSubmit(name, choosedColor);
  };

  return (
    <form className={clsx(styles.ColumnForm, className)} onSubmit={handleSubmit}>
      <InputBase
        className={styles.ColumnFormInput}
        placeholder={t('contacts-page:BoardForm.placeholder')}
        onChange={handleChange}
        value={name}
        startAdornment={
          <PaletteForm
            defaultColor={choosedColor}
            onColorPick={setChoosedColor}
            popperZindex={paletteZindex}
          />
        }
        inputProps={{ 'data-hj-allow': '' }}
      />
      <div className={styles.ColumnFormActions}>
        <Button
          className={styles.NewColumnAdd}
          variant={'contained'}
          type="submit"
          disabled={name.length === 0}
        >
          {t('common:save')}
        </Button>
        <Button onClick={onCancel} className={styles.NewColumnClose}>
          {t('duplicates:Cancel_action')}
        </Button>
      </div>
    </form>
  );
};

export default CreateTagForm;
