import { MouseEventHandler, MutableRefObject, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation, TFunction } from 'next-i18next';

import { useFormat } from '../../../../../../../lib/date-fns';
import { TeamMemberEntity } from '../../../../../../../db/entities/workspace/WorkspaceEntity';
import NoteEntity from '../../../../../../../db/entities/note/NoteEntity';

type TArgs = {
  isCurrentWorkspaceCollaborative: boolean;
  members: TeamMemberEntity[];
  handleSelectNote: (id: string) => void;
  handleDeleteNote: (id: string) => void;
  note: NoteEntity;
  userEmail: string;
};

type TReturn = {
  onSelectClick: () => void;
  onDeleteClick: MouseEventHandler<HTMLButtonElement>;
  nameToShow: string;
  shouldShowUser: boolean;
  avatarThumbnail: string;
  content: string;
  t: TFunction;
  descriptionText: string;
  contentRef: MutableRefObject<HTMLParagraphElement>;
  showCollapseButton: boolean;
  isExpanded: boolean;
  onCollapseButtonClick: () => void;
  COLLAPSED_LIST_HEIGHT: number;
};

type TUseNoteItemViewModel = (args: TArgs) => TReturn;

const COLLAPSED_CONTENT_ROWS_COUNT = 6;
const ROWS_COUNT_TO_COLLAPSE = 6;
const ROW_HEIGHT_PX = 20;

const useNoteItemViewModel: TUseNoteItemViewModel = ({
  members,
  isCurrentWorkspaceCollaborative,
  handleDeleteNote,
  handleSelectNote,
  note,
  userEmail,
}) => {
  const format = useFormat();
  const { t } = useTranslation('contact-details');
  const { createdBy, updatedBy, createdAt, updatedAt, content } = note;
  const wasUpdated = updatedAt > createdAt;
  const userEmailToShow = wasUpdated ? updatedBy : createdBy;
  const teammate = members.find((member) => member.email === userEmailToShow);
  const nameToShow = teammate ? teammate.fullName || teammate.email : userEmailToShow;
  const itsCurrentUser = userEmailToShow === userEmail;
  const shouldShowUser = isCurrentWorkspaceCollaborative || !itsCurrentUser;
  const dateToShow = wasUpdated ? updatedAt : createdAt;
  const dateToShowFormatted = format(dateToShow * 1000, 'iii d MMM HH:mm');
  const lastActionText = wasUpdated ? t('notes.updated') : t('notes.created');
  const descriptionText = `${
    shouldShowUser ? nameToShow : ''
  } ${lastActionText} ${dateToShowFormatted}`;
  const contentRef = useRef<HTMLParagraphElement>(null);
  const [showCollapseButton, setShowCollapseButton] = useState(false);
  const [isExpanded, setIsExpanded] = useState(undefined);

  const onSelectClick = () => {
    handleSelectNote(note.id);
  };

  const onDeleteClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    handleDeleteNote(note.id);
  };

  const onCollapseButtonClick = () => {
    setIsExpanded(!isExpanded);
  };

  useLayoutEffect(() => {
    const contentHeight = contentRef.current.clientHeight;
    const shouldShowCollapse = contentHeight / ROW_HEIGHT_PX > ROWS_COUNT_TO_COLLAPSE;

    setShowCollapseButton(shouldShowCollapse);
    setIsExpanded(!shouldShowCollapse);

    return () => {
      setShowCollapseButton(false);
      setIsExpanded(undefined);
    };
  }, [content]);

  return {
    t,
    onSelectClick,
    onDeleteClick,
    onCollapseButtonClick,
    nameToShow,
    shouldShowUser,
    avatarThumbnail: teammate?.thumbnail,
    content: note.content,
    descriptionText,
    contentRef,
    showCollapseButton,
    isExpanded,
    COLLAPSED_LIST_HEIGHT: ROW_HEIGHT_PX * COLLAPSED_CONTENT_ROWS_COUNT,
  };
};

export default useNoteItemViewModel;
