import { Menu } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { selectContactsMap } from 'slices/contactsSlice';
import { useAppSelector } from 'src/app/hooks';

import Contact from 'types/contact';
import DialogItem from '../ContactContextDialog/components/DialogItem';

interface IProps {
  contactId: Contact['uuid'];
  anchorEl: HTMLLIElement | null;
  onClose: () => void;
}

const MessageDialog: React.FC<IProps> = ({ contactId, anchorEl, onClose }) => {
  const { t } = useTranslation('dialogs');
  const contactsMap = useAppSelector(selectContactsMap);
  const relatedContact = contactsMap[contactId] as Contact;

  if (!relatedContact) return null;

  const contactPhone = relatedContact.phones?.[0]?.normalized_phone;
  const contactEmail = Array.isArray(relatedContact.emails)
    ? relatedContact.emails?.[0]?.email
    : undefined;
  const smsLink = `sms://${contactPhone}`;
  const waLink = `https://wa.me/${contactPhone}`;
  const emailLink = `mailto:${contactEmail}`;

  const onClick = (link) => {
    window.open(link, '_blank');
  };

  return (
    <Menu
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorEl={anchorEl}
      onClose={onClose}
      open={!!anchorEl}
      classes={{ paper: 'leader-common-dialog' }}
      MenuListProps={{ onMouseLeave: onClose }}
    >
      <DialogItem
        onClick={() => onClick(smsLink)}
        text={t('contact_context_dialog.message.text_message')}
      />
      <DialogItem
        onClick={() => onClick(waLink)}
        text={t('contact_context_dialog.message.whatsapp')}
      />
      {contactEmail && (
        <DialogItem onClick={() => onClick(emailLink)} text={t('duplicates:Email_field')} />
      )}
    </Menu>
  );
};

export default MessageDialog;
