enum CIO_EVENTS {
  SIGN_UP = 'sign_up',
  LOGIN_SUCCESS = 'login_success',
  FULL_REPORT_PERSON = 'user_viewed_full_report_for_person',
  FULL_REPORT_COMPANY = 'user_viewed_full_report_for_organization',
  VIEW_PLANS_PAGE = 'user_viewed_plans_page',
  VIEW_PAYMENT_PAGE = 'user_viewed_payment_details_page',
  SUCCESSFULLY_PURCHASED = 'user_successfully_purchased',
}
export default CIO_EVENTS;
