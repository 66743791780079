import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { useAppDispatch } from '../../../../../../app/hooks';
import { updateProposal } from '../../../../../../slices/proposalsSlice';
import { generatePdf } from '../../../../../../api/proposalsAPI/api';
import downloadFileByLink from '../../../../../../utils/downloadFileByLink';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';
import { useCurrentUser } from 'src/features2/user/ui/hooks';

export default function useDownloadProposal(): [
  (proposal: ProposalEntity) => Promise<void>,
  boolean
] {
  const { t } = useTranslation('contact-details');
  const { currentUser } = useCurrentUser();
  const dispatch = useAppDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const [isGenerating, setGenerating] = useState(false);

  const download = async (proposal: ProposalEntity) => {
    try {
      setGenerating(true);

      const tempUrl = await generatePdf({
        ...proposal,
        settings: { ...proposal.settings, lang: currentUser.settings.lang },
      });

      if (proposal.isDraft) {
        await dispatch(
          updateProposal({
            ...proposal,
            isDraft: false,
            updatedAt: Date.now(),
            sentAt: Date.now(),
          })
        ).unwrap();
      }

      downloadFileByLink(tempUrl, proposal.title);
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    } finally {
      setGenerating(false);
    }
  };

  return [download, isGenerating];
}
