import clsx from 'clsx';
import styles from '../ContactCard.module.css';
export default function Badges({
  list,
  className,
}: {
  list: Array<{ title: string; color: string }>;
  className?: string;
}) {
  if (list.length === 0) {
    return null;
  }

  const [firstBadge, secondBadge, ...restBadges] = list;

  return (
    <div className={clsx(styles.tags, className)}>
      <div className={styles.tagItem} style={{ backgroundColor: firstBadge.color }}>
        {firstBadge.title}
      </div>
      {secondBadge && (
        <div
          className={styles.tagItem}
          style={{ backgroundColor: secondBadge.color, marginLeft: 5 }}
        >
          {secondBadge.title}
        </div>
      )}
      {restBadges.length > 0 && (
        <span title={restBadges.map(({ title }) => title).join(', ')}>+{restBadges.length}</span>
      )}
    </div>
  );
}
