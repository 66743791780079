import { Tooltip, IconButton, Popover } from '@material-ui/core';
import React, { useState } from 'react';
import CustomFieldEntity from 'src/db/entities/customField/CustomFieldEntity';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';
import InputBase from 'components/UI/Inputs/InputBase';
import BaseButton from 'components/UI/Buttons/BaseButton';
import CustomFieldValueEntity from 'src/db/entities/customFieldValue/CustomVieldValueEntity';
import { useInjection } from 'src/app/ioc/ioc.react';
import { ICustomFieldValueUseCase } from 'src/features2/common/customFieldValue/domain/abstractions/ICustomFieldValueUseCase';
import { CUSTOM_FIELD_VALUE_TYPES } from 'src/features2/common/customFieldValue/di/types';
import { v4 } from 'uuid';

interface Props {
  contactUuid: string;
  field: CustomFieldEntity;
  value: CustomFieldValueEntity | undefined;
}

const CustomField: React.FC<Props> = ({ field, value, contactUuid }) => {
  const { t } = useTranslation('customFields');
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [editValue, setEditValue] = useState(value?.value ?? '');
  const customFieldValueUseCase = useInjection<ICustomFieldValueUseCase>(
    CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueUseCase
  );

  const onEdit: React.MouseEventHandler<HTMLButtonElement> = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };

  const onCloseEdit = () => {
    setAnchorEl(null);
    setEditValue(value?.value ?? '');
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    setEditValue(value);
  };

  const sendNewValue = (val: string) => {
    customFieldValueUseCase
      .addValue({
        uuid: value?.uuid ?? v4(),
        customFieldUuid: field.uuid,
        value: val,
        contactUuid,
      })
      .subscribe({
        complete: () => {
          setAnchorEl(null);
        },
      });
  };

  const onSaveValue = () => {
    sendNewValue(editValue);
  };

  const onDelete = () => {
    setEditValue('');
    sendNewValue('');
  };

  return (
    <div className={styles.fieldWrapper} key={field.uuid}>
      <div className={styles.fieldsInfo}>
        <span className={styles.fieldLabel}>{field.name}</span>
        <div className={styles.fieldValue}>
          <span className={styles.fieldText}>
            {value?.value ? (
              value.value
            ) : (
              <BaseButton
                onClick={onEdit}
                disableRipple
                className={styles.fieldsAddButton}
                variant="text"
                color="secondary"
              >
                {t('field.add_value_button')}
              </BaseButton>
            )}
          </span>
          <Tooltip placement={'top'} title={t('tooltips.edit', { ns: 'contact-details' })}>
            <IconButton className={styles.fieldEditButton} onClick={onEdit}>
              <CreateOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <Popover
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onCloseEdit}
        classes={{ paper: styles.EditPopover }}
      >
        <div className={styles.Title}>{field.name}</div>
        <div className={styles.InputWrapper}>
          <InputBase
            value={editValue}
            placeholder={t('field.add_value_placeholder')}
            onChange={onChange}
          />
          <BaseButton variant="text" color="secondary" onClick={onDelete}>
            {t('Delete_action', { ns: 'duplicates' })}
          </BaseButton>
        </div>
        <div className={styles.Buttons}>
          <BaseButton variant="text" color="secondary" onClick={onCloseEdit}>
            {t('Cancel_action', { ns: 'duplicates' })}
          </BaseButton>
          <BaseButton onClick={onSaveValue}>{t('Save_action', { ns: 'duplicates' })}</BaseButton>
        </div>
      </Popover>
    </div>
  );
};

export default CustomField;
