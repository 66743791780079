import React, { useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import clsx from 'clsx';

import Button from 'components/UI/Buttons/BaseButton';
import { FormValues } from '../types';
import Select from 'components/UI/Inputs/Select';
import TextField from 'components/UI/Inputs/TextField';
import styles from 'styles/addContact/addContacts.module.css';
import styles2 from 'styles/contactDetails/contactDetails.module.scss';
import { FormTypes, FormArrayNames, PhonesFormSelectOptions, getFieldName } from './formData';

interface IProps {
  isWithoutPhoneNumber: boolean;
}

const PhonesInput: React.FC<IProps> = ({ isWithoutPhoneNumber }) => {
  const { t } = useTranslation(['add-contact-page', 'common']);
  const { control } = useFormContext<FormValues>();
  const { fields, append, remove, update } = useFieldArray<FormValues, FormArrayNames.PHONES>({
    control,
    name: FormArrayNames.PHONES,
  });

  useEffect(() => {
    if (isWithoutPhoneNumber) {
      update(0, { type: PhonesFormSelectOptions.MOBILE, number: '' });
    }
  }, [isWithoutPhoneNumber]);

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <Controller
              name={getFieldName(FormTypes.PHONE, index, true)}
              control={control}
              defaultValue={PhonesFormSelectOptions.MOBILE}
              render={({ field }) => (
                <Select
                  {...field}
                  variant="standard"
                  options={[
                    { value: PhonesFormSelectOptions.MOBILE, text: t('mobile') },
                    { value: PhonesFormSelectOptions.HOME, text: t('landline') },
                    { value: PhonesFormSelectOptions.FAX, text: t('fax') },
                  ]}
                />
              )}
            />
            <Controller
              name={getFieldName(FormTypes.PHONE, index)}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <div className={styles2.formField}>
                    <TextField
                      {...field}
                      className={clsx(styles2.formFieldInput, styles2.contactFormTitle)}
                      error={!!fieldState.error}
                      helperText={t(fieldState.error?.message)}
                      placeholder={t('duplicates:Phone_number_field')}
                      fullWidth
                      margin="dense"
                    />
                    {index !== 0 && (
                      <Button
                        className={styles2.formDeleteButton}
                        variant="text"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        {t('duplicates:Delete_action')}
                      </Button>
                    )}
                  </div>
                );
              }}
            />
          </div>
        );
      })}
      <button
        className={styles.addFieldButton}
        type="button"
        onClick={() => {
          append({ type: PhonesFormSelectOptions.MOBILE, number: '' });
        }}
      >
        {t('addAnotherPhone')}
      </button>
    </div>
  );
};

export default PhonesInput;
