import Popover, { PopoverProps } from '@material-ui/core/Popover';

import CreateTagForm, { CreateTagFormProps } from '../../CreateTagForm/CreateTagForm';

type CreateTagPopupProps = Omit<CreateTagFormProps, 'onCancel'> & {
  anchorEl: PopoverProps['anchorEl'];
  onClose: () => void;
  className?: string;
  formClassName?: string;
};

export default function CreateTagPopup(props: CreateTagPopupProps) {
  const { anchorEl, className, formClassName, onClose, ...rest } = props;

  return (
    <Popover open={!!anchorEl} anchorEl={anchorEl} onClose={onClose} className={className}>
      <CreateTagForm {...rest} className={formClassName} onCancel={onClose} paletteZindex={2500} />
    </Popover>
  );
}
