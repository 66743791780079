import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Button from '../../../../components/UI/Buttons/BaseButton';
import styles from './Section.module.css';
import Index from '../../../../features2/localization/ui/DirectedText';

export default function Section({
  children,
  title,
  entityName,
  entitiesNumber,
  onClickCreate,
  withButton = true,
  entitiesCountToExpand = 2,
}: {
  children: (
    expanded: boolean,
    setEntitiesNumberInternal: Dispatch<SetStateAction<number>>
  ) => React.ReactNode;
  title: string;
  entityName: string;
  entitiesNumber?: number;
  onClickCreate: () => void;
  withButton?: boolean;
  entitiesCountToExpand?: number;
}) {
  const { t } = useTranslation('contact-details');

  const [expanded, setExpanded] = useState(false);
  const [entitiesNumberInternal, setEntitiesNumberInternal] = useState(0);
  const entitiesNumberResult =
    entitiesNumber !== undefined ? entitiesNumber : entitiesNumberInternal;

  return (
    <section className={styles.wrapper}>
      <div className={styles.top}>
        <h2 className={styles.title}>
          {entitiesNumberResult > 1 ? (
            <Index>
              {entitiesNumberResult} {title}
            </Index>
          ) : (
            <>{title}</>
          )}
        </h2>
        {entitiesNumberResult > entitiesCountToExpand && (
          <>
            {expanded ? (
              <button className={styles.seeAllButton} onClick={() => setExpanded(false)}>
                {t('actions.seeLess')}
              </button>
            ) : (
              <button className={styles.seeAllButton} onClick={() => setExpanded(true)}>
                {t('actions.seeAll')}
              </button>
            )}
          </>
        )}
      </div>
      <div className={styles.list}>{children(expanded, setEntitiesNumberInternal)}</div>
      {withButton && (
        <Button
          onClick={onClickCreate}
          className={styles.addButton}
          variant="text"
          textSize="small"
        >
          + {t('common:action.create')} {entityName}
        </Button>
      )}
    </section>
  );
}
