import { useTranslation } from 'react-i18next';
import useNotesViewModel from '../useNotesViewModel';
import styles from './styles.module.scss';
import NoteOption from 'icons/note-option.svg';
import React from 'react';

type Props = {
  onClick: () => void;
};
const AddNoteMenuOption: React.FC<Props> = ({ onClick }) => {
  const { t } = useTranslation();
  const { handleOpenForm } = useNotesViewModel();
  return (
    <>
      <button
        className={styles.AddNoteOption}
        onClick={() => {
          onClick();
          handleOpenForm();
        }}
      >
        <NoteOption />
        <span>{t('contact-details:actions.more.add_note')}</span>
      </button>
    </>
  );
};
export default AddNoteMenuOption;
