import { useContext } from 'react';
import { Trans } from 'react-i18next';
import Image from 'next/image';

import PlusIcon from '../../../../../../public/images/icons/bold-plus.svg';
import styles from './NoTaggedContacts.module.css';
import LocalizationContext from '../../../../../features2/localization/ui/LocalizationContext';

export default function NoTaggedContacts() {
  const { isRTL } = useContext(LocalizationContext);

  return (
    <div className={styles.wrapper} data-empty-state>
      <div className={styles.content} style={{ direction: isRTL ? 'rtl' : 'ltr' }}>
        <Trans i18nKey="contacts-page:empty_state.no_tags.description">
          You have no contacts in your workflow. <br /> Click on the
          <PlusIcon className={styles.plusIcon} /> button to add them. Drag contacts to change their
          status.
        </Trans>
      </div>
      <div className={styles.imageWrapper}>
        <Image src={'/images/no-tagged-contacts.png'} width={325} height={180} />
      </div>
    </div>
  );
}
