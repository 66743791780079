import { useRef } from 'react';

import { useInjectionWithRef } from '../../../../../app/ioc/ioc.react';
import { IAmplitudeManager } from '../../../../../analytics/amplitude';
import { TYPES as GLOBAL_TYPES } from '../../../../../app/ioc/types';
import { useFilesLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

const useAddFileButtonViewModel = ({ limitsCheckFailedCb }) => {
  const { checkLimits } = useFilesLimits();
  const Amplitude = useInjectionWithRef<IAmplitudeManager>(GLOBAL_TYPES.AmplitudeManager);
  const hiddenInputRef = useRef<HTMLInputElement>(null);

  const onButtonClick = () => {
    Amplitude.trackEvent('Add_file_tapped');
    if (!checkLimits()) {
      limitsCheckFailedCb && limitsCheckFailedCb();
    } else {
      hiddenInputRef.current.click();
    }
  };

  return {
    onButtonClick,
    hiddenInputRef,
  };
};

export default useAddFileButtonViewModel;
