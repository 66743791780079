import { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import useDuplicateContact from '../../../../contacts/hooks/useDuplicateContact';

import {
  selectCurrentUserMemberData,
  selectIsCurrentWorkspaceCollaborative,
  selectCurrentWorkspace,
} from 'slices/workspacesSlice';
import styles from '../ActivityTab.module.css';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks';
import { selectCurrentContact } from 'slices/contactsSlice';
import {
  selectNotes,
  updateNote,
  createNote,
  deleteNote,
  selectOpenFormNote,
  setOpenFormNote,
} from 'slices/notesSlice';
import Form from './Form';
import DeleteDialog from '../../../../../components/UI/Dialogs/DeleteDialog';
import Section from '../Section';
import { trackEvent } from 'src/analytics/amplitude';
import useRedirectEffect from 'src/app/hooks/ui/useRedirectEffect';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import { TRedirectEffect } from 'types/ui';
import { setRedirectEffect } from 'slices/uiSlice';
import NoteEntity from 'src/db/entities/note/NoteEntity';
import NoteItem from './components/NoteItem';

import { useNotesLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';
import useFBAnalytics from 'src/analytics/FB/ui/useFBAnalytics';
import FB_EVENTS from 'src/analytics/FB/domain/enums/FB_EVENTS';

const Notes = () => {
  const { t } = useTranslation('contact-details');
  const { checkLimits } = useNotesLimits();

  const dispatch = useAppDispatch();
  const isCurrentWorkspaceCollaborative = useAppSelector(selectIsCurrentWorkspaceCollaborative);
  const { members } = useAppSelector(selectCurrentWorkspace);
  const openFormCreateNote = useAppSelector(selectOpenFormNote);
  const notes = useAppSelector(selectNotes);
  const currentContact = useAppSelector(selectCurrentContact);
  const [openDialog, setOpenDialog] = useState(false);
  const [openFormEdit, setOpenFormEdit] = useState(false);
  const [noteId, setNoteId] = useState();
  const [selectedNote, setSelectedNote] = useState('');
  const [currentContactNotes, setCurrentContactNotes] = useState<NoteEntity[]>([]);
  const redirectEffect = useRedirectEffect(RedirectEffectTargetType.NOTE) as TRedirectEffect;
  const { trackEvent: FBTrackEvent } = useFBAnalytics();
  useEffect(() => {
    const notesArr = notes
      .filter(({ links }) => {
        return links?.direct?.type === 'contact' && links?.direct?.id === currentContact.uuid;
      })
      .sort((a, b) => b.updatedAt - a.updatedAt);
    setCurrentContactNotes(notesArr);
  }, [notes, currentContact]);

  const duplicateContact = useDuplicateContact();

  const { email } = useAppSelector(selectCurrentUserMemberData);

  const prepareContact = async () => {
    let id = currentContact.uuid;

    if (currentContact.workspace_id === null) {
      id = await duplicateContact(currentContact.uuid);
    }

    return id;
  };

  const handleCreateNote = async (note) => {
    if (note.length > 0) {
      const contactId = await prepareContact();
      dispatch(createNote({ contactId, note }));
    }
    FBTrackEvent('trackCustom', FB_EVENTS.ADD_NOTE);
    trackEvent('Add_note', { type: 'Contact details' });
  };
  const handleEditNote = async (note: Partial<NoteEntity>) => {
    await prepareContact();

    dispatch(updateNote({ id: note.id, diff: note }));

    trackEvent('Edit_note');

    setSelectedNote('');
  };

  const handleClick = () => {
    checkLimits() && dispatch(setOpenFormNote(true));
  };
  const handleDeleteNote = (noteId) => {
    setNoteId(noteId);
    setOpenDialog(true);
    trackEvent('Delete_note');
  };
  const handleSuccessDeleteNote = () => {
    dispatch(deleteNote({ contactId: currentContact.uuid, noteId }));
  };
  const handleCloseForm = () => {
    setSelectedNote('');
  };

  const handleSelectNote = (noteId) => {
    if (!openFormEdit) {
      setOpenFormEdit(true);
      setSelectedNote(noteId);
    }
  };

  useLayoutEffect(() => {
    if (redirectEffect && redirectEffect.targetType === RedirectEffectTargetType.NOTE) {
      setSelectedNote(redirectEffect.targetId);
      setOpenFormEdit(true);
      dispatch(setRedirectEffect(null));
    }
  }, [redirectEffect, dispatch]);

  return (
    <Section
      title={t('notes.title')}
      entityName={t('notes.entity')}
      entitiesNumber={currentContactNotes.length}
      onClickCreate={handleClick}
    >
      {(expanded) => {
        const visibleNotes = expanded ? currentContactNotes : currentContactNotes.slice(0, 2);

        return (
          <div className={styles.notesWrapper}>
            <div className={styles.notesContainer}>
              {visibleNotes.map((note) => {
                if (note.id == selectedNote) {
                  return (
                    <Form
                      key={note.id}
                      type={'edit'}
                      open={openFormEdit}
                      setOpen={setOpenFormEdit}
                      initialValue={note.content}
                      onClose={handleCloseForm}
                      onSuccess={(noteContent) => handleEditNote({ ...note, content: noteContent })}
                      placeholder={t('notes.newNote.placeholder')}
                    />
                  );
                }

                return (
                  <NoteItem
                    key={note.id}
                    note={note}
                    members={members}
                    isCurrentWorkspaceCollaborative={isCurrentWorkspaceCollaborative}
                    userEmail={email}
                    handleSelectNote={handleSelectNote}
                    handleDeleteNote={handleDeleteNote}
                  />
                );
              })}
            </div>
            <Form
              onClose={handleCloseForm}
              open={openFormCreateNote}
              setOpen={(flag) => dispatch(setOpenFormNote(flag))}
              onSuccess={(note) => handleCreateNote(note)}
              placeholder={t('notes.newNote.placeholder')}
            />
            <DeleteDialog
              title={t('notes.delete.title')}
              description={t('notes.delete.description')}
              open={openDialog}
              onClose={() => setOpenDialog(false)}
              onSuccess={handleSuccessDeleteNote}
              titleClassName={styles.DialogTitle}
            />
          </div>
        );
      }}
    </Section>
  );
};
export default Notes;
