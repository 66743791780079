import React from 'react';
import { useTranslation } from 'next-i18next';

import FileIcon from 'icons/file.svg';
import styles from './styles.module.scss';
import FilesInput from '../FilesInput';
import useAddFileButtonViewModel from './useAddFileButtonViewModel';

interface IProps {
  onFilesAddedToQueue?: () => void;
}

const AddFileButton: React.FC<IProps> = ({ onFilesAddedToQueue }) => {
  const { t } = useTranslation('files');
  const { onButtonClick, hiddenInputRef } = useAddFileButtonViewModel({
    limitsCheckFailedCb: onFilesAddedToQueue,
  });

  return (
    <>
      <button className={styles.AddFileButton} onClick={onButtonClick}>
        <FileIcon />
        <span>{t('add_file_icon_text')}</span>
      </button>
      <FilesInput onFilesAddedToQueue={onFilesAddedToQueue} ref={hiddenInputRef} hidden />
    </>
  );
};

export default AddFileButton;
