import React from 'react';
import clsx from 'clsx';

import NoteEntity from '../../../../../../../db/entities/note/NoteEntity';
import styles from './styles.module.scss';
import Avatar from 'components/UI/Avatar';
import Tooltip from 'components/UI/Tooltip';
import EditIcon from 'icons/edit.svg';
import RemoveIcon from 'icons/remove.svg';
import { TeamMemberEntity } from '../../../../../../../db/entities/workspace/WorkspaceEntity';
import useNoteItemViewModel from './useNoteItemViewModel';

interface IProps {
  note: NoteEntity;
  members: TeamMemberEntity[];
  isCurrentWorkspaceCollaborative: boolean;
  userEmail: string;
  handleSelectNote: (id: string) => void;
  handleDeleteNote: (id: string) => void;
}

const NoteItem: React.FC<IProps> = (props) => {
  const {
    onSelectClick,
    onCollapseButtonClick,
    shouldShowUser,
    nameToShow,
    onDeleteClick,
    avatarThumbnail,
    content,
    t,
    descriptionText,
    contentRef,
    showCollapseButton,
    isExpanded,
    COLLAPSED_LIST_HEIGHT,
  } = useNoteItemViewModel(props);

  const contentStyles = isExpanded === false ? { height: COLLAPSED_LIST_HEIGHT } : undefined;
  const contentClassName =
    isExpanded === undefined ? undefined : isExpanded ? styles.Expanded : styles.Collapsed;

  return (
    <div className={styles.ListItem} role={'button'} tabIndex={0}>
      <p
        ref={contentRef}
        className={clsx(styles.NoteContent, contentClassName)}
        style={contentStyles}
      >
        {content}
      </p>
      {showCollapseButton && (
        <button onClick={onCollapseButtonClick} className={styles.CollapseButton}>
          {isExpanded ? t('duplicates:See_less_action') : t('duplicates:Read_more_action')}
        </button>
      )}
      <div className={styles.AdditionalInfo}>
        {shouldShowUser && (
          <Avatar
            size={16}
            thumbnail={avatarThumbnail}
            name={nameToShow}
            className={styles.NoteUserAvatar}
          />
        )}
        <span>{descriptionText}</span>
        <div className={styles.NoteActions}>
          <Tooltip placement={'top'} title={t('duplicates:Edit_action')}>
            <button
              className={clsx(styles.ActionButton, styles.EditAction)}
              onClick={onSelectClick}
            >
              <EditIcon className={styles.ButtonIcon} />
            </button>
          </Tooltip>
          <Tooltip placement={'top'} title={t('duplicates:Delete_action')}>
            <button
              className={clsx(styles.ActionButton, styles.DeleteAction)}
              onClick={onDeleteClick}
            >
              <RemoveIcon className={styles.ButtonIcon} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default NoteItem;
