import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import DirectedText from '../../../../../../features2/localization/ui/DirectedText';

interface IProps {
  onClick?: React.MouseEventHandler<HTMLElement>;
  onMouseOver?: React.MouseEventHandler<HTMLElement>;
  icon?: JSX.Element;
  text?: string;
  isExpanding?: boolean;
  isRedText?: boolean;
  children?: JSX.Element;
  className?: string;
}

const DialogItem: React.FC<IProps> = ({
  onClick,
  icon,
  text,
  isExpanding,
  isRedText,
  children,
  className,
  onMouseOver,
}) => (
  <MenuItem
    className={clsx(
      styles.DialogItem,
      isExpanding && styles.IsExpanding,
      isRedText && styles.Red,
      className
    )}
    onClick={onClick}
    onMouseOver={onMouseOver}
  >
    {icon}
    {children || (
      <span>
        <DirectedText>{text}</DirectedText>
      </span>
    )}
  </MenuItem>
);

export default DialogItem;
