import React from 'react';
import clsx from 'clsx';

import useCSVInputViewModel from './useCSVInputViewModel';
import styles from './styles.module.scss';
import { useImportContactLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

interface IImportCSVInputProps {
  inputId: string;
  className?: string;
  inputProps?: {
    tagName?: string;
    columnId?: string;
  };
}

const CSVInput: React.FC<IImportCSVInputProps> = ({ className, inputProps, inputId }) => {
  const { handleInputCSVSelect } = useCSVInputViewModel(inputProps);
  const { checkLimits } = useImportContactLimits();

  const handleChangeInput = (e) => {
    e.preventDefault();
    handleInputCSVSelect(e.target.files[0]);
  };

  const onClickHandler = (e) => {
    if (!checkLimits()) {
      e.preventDefault();
    }
  };

  return (
    <input
      className={clsx(styles.Input, className)}
      accept={
        '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
      }
      type="file"
      name={`file_${inputId}`}
      value=""
      id={`file_${inputId}`}
      onChange={handleChangeInput}
      onClick={onClickHandler}
    />
  );
};

export default CSVInput;
