import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useSnackbar } from 'notistack';

import { useAppDispatch } from 'src/app/hooks';
import { deleteProposal as thunkDeleteProposal } from '../../../../../../slices/proposalsSlice';

import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import { trackEvent } from 'src/analytics/amplitude';

export default function useDeleteProposal(): [(uuid: string) => Promise<void>, boolean] {
  const { t } = useTranslation('contact-details');

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useAppDispatch();

  const [isDeleting, setDeleting] = useState(false);

  const deleteProposal = async (uuid: string) => {
    try {
      setDeleting(true);

      await dispatch(thunkDeleteProposal(uuid));
      trackEvent('Delete_quote');
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    } finally {
      setDeleting(false);
    }
  };

  return [deleteProposal, isDeleting];
}
