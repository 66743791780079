import dynamic from 'next/dynamic';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { PhoneNumberUtil, PhoneNumberFormat as PNF } from 'google-libphonenumber';

import { getUserLocalStorage } from '../../../../utils';
import styles from 'styles/contactDetails/Insights.module.css';
import { useAppSelector } from '../../../../app/hooks';
import { selectCurrentContact } from 'slices/contactsSlice';
import useCustomerIOViewModel from 'src/analytics/customerIO/ui/useCustomerIOViewModel';
import CIO_EVENTS from 'src/analytics/customerIO/domain/enums/CIO_EVENTS';
import {
  selectSelectedCompanyUuid,
  selectSelectedCompanyId,
  selectSelectedPersonUuid,
  setSelectedCompanyUuid,
  setSelectedPersonUuid,
  setSelectedCompanyId,
  setSelectedReport,
  selectSelectedReport,
  selectSelectedPersonEmail,
  selectSelectedPersonPhoneNumber,
  setReportHistory,
  clearReportData,
} from 'slices/fullReportSlice';
import { selectUserProfile } from 'slices/userSlice';
import { ChangeContactDetails } from '../../types';
import { trackEvent } from 'src/analytics/amplitude';
import { i18n } from 'next-i18next';
import { getDirectTokenLocalStorage } from '../../../../utils/helpers';

const FullReportPerson = dynamic(
  import('@syncai/full-report').then((mod) => mod.PersonFullReport),
  { ssr: false }
);
const FullReportCompany = dynamic(
  import('@syncai/full-report').then((mod) => mod.CompanyFullReport),
  { ssr: false }
);

interface FullReportComponentProps {
  changeContactDetails: ChangeContactDetails;
  resetContactDetails: () => void;
  setReportData: (d: unknown) => void;
}

enum ReportType {
  PERSON,
  COMPANY,
}

const FullReportComponent: React.FC<FullReportComponentProps> = ({
  changeContactDetails,
  resetContactDetails,
  setReportData,
}) => {
  const currentContact = useAppSelector(selectCurrentContact);
  const user = useAppSelector(selectUserProfile);
  const dispatch = useDispatch();
  const { trackEvent: trackCIOEvent } = useCustomerIOViewModel();
  const selectedCompanyUuid = useAppSelector(selectSelectedCompanyUuid);
  const selectedCompanyId = useAppSelector(selectSelectedCompanyId);
  const selectedPersonUuid = useAppSelector(selectSelectedPersonUuid);
  const selectedPersonPhoneNumber = useAppSelector(selectSelectedPersonPhoneNumber);
  const selectedPersonEmail = useAppSelector(selectSelectedPersonEmail);
  const selectedReport = useAppSelector(selectSelectedReport);

  const isPersonReportSelected = selectedReport === ReportType.PERSON;
  const isCompanyReportSelected = selectedReport === ReportType.COMPANY;
  const correctPersonEmail = selectedPersonEmail
    ? selectedPersonEmail
    : currentContact.emails?.[0]?.email;
  const correctPersonPhoneNumber = selectedPersonPhoneNumber
    ? selectedPersonPhoneNumber
    : currentContact.phones[0]?.normalized_phone;
  const phoneUtil = PhoneNumberUtil.getInstance();

  const getCorrectFormattedPhoneNumber = () => {
    if (correctPersonPhoneNumber) {
      let result = correctPersonPhoneNumber.startsWith('+')
        ? correctPersonPhoneNumber
        : `+${correctPersonPhoneNumber}`;

      try {
        const parsedPhone = phoneUtil.parse('+' + result, '');
        result = phoneUtil.format(parsedPhone, PNF.E164);
      } catch {}

      return result;
    }

    return null;
  };

  const correctFormattedPhoneNumber = getCorrectFormattedPhoneNumber();

  useEffect(() => {
    trackEvent('user_viewed_full_report_for_person');
    trackCIOEvent(CIO_EVENTS.FULL_REPORT_PERSON);
    if (!selectedPersonUuid && !isCompanyReportSelected) {
      dispatch(
        setReportHistory([
          {
            title:
              currentContact.suggestion_name ||
              currentContact.name ||
              currentContact.phones[0]?.phone ||
              currentContact.emails[0]?.email ||
              '',
            isCompanyReport: isCompanyReportSelected,
            isPersonReport: isPersonReportSelected,
            personEmail: correctPersonEmail,
            personPhoneNumber: correctFormattedPhoneNumber,
          },
        ])
      );
    }
  }, []);

  // @ts-ignore
  useEffect(() => {
    return () => dispatch(clearReportData());
  }, []);

  const defaultProps = {
    backendUrl: process.env.NEXT_PUBLIC_API_URL,
    requestAuthData: {
      Authorization: getUserLocalStorage()?.idToken ?? getDirectTokenLocalStorage() ?? '',
      xSyncaiAppId: process.env.NEXT_PUBLIC_APP_ID,
      xSyncaiAppVersion: process.env.NEXT_PUBLIC_APP_VERSION,
      xSyncaiAppUuid: process.env.NEXT_PUBLIC_APP_UUID,
      xSyncaiAppPlatform: process.env.NEXT_PUBLIC_APP_PLATFORM,
    },
    userEmail: user.email,
    external_setReportData: setReportData,
  };

  const onPersonReportCompanyReportView = (companyId) => {
    dispatch(setSelectedCompanyId(companyId));
    dispatch(setSelectedReport(ReportType.COMPANY));
    trackEvent('user_viewed_full_report_for_organization');
    trackCIOEvent(CIO_EVENTS.FULL_REPORT_COMPANY);
  };

  const onCompanyReportCompanyReportView = (uuid, companyId) => {
    dispatch(setSelectedCompanyUuid(uuid));
    dispatch(setSelectedCompanyId(companyId));
    dispatch(setSelectedReport(ReportType.COMPANY));
    trackEvent('user_viewed_full_report_for_organization');
    trackCIOEvent(CIO_EVENTS.FULL_REPORT_COMPANY);
  };

  const onPersonReportPersonReportView = (personUuid) => {
    dispatch(setSelectedPersonUuid(personUuid));
    dispatch(setSelectedReport(ReportType.PERSON));
    trackEvent('user_viewed_full_report_for_person');
    trackCIOEvent(CIO_EVENTS.FULL_REPORT_PERSON);
  };

  return (
    <div className={styles.wrapper}>
      {isPersonReportSelected ? (
        <FullReportPerson
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          i18n={i18n}
          onViewInsights={onPersonReportCompanyReportView}
          personEmail={selectedPersonUuid ? null : correctPersonEmail}
          personPhoneNumber={selectedPersonUuid ? null : correctFormattedPhoneNumber}
          changeContactDetails={changeContactDetails}
          resetContactDetails={resetContactDetails}
          personUuid={selectedPersonUuid}
          {...defaultProps}
        />
      ) : (
        isCompanyReportSelected && (
          <FullReportCompany
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            i18n={i18n}
            onCompanyClick={onCompanyReportCompanyReportView}
            onPersonClick={onPersonReportPersonReportView}
            companyId={selectedCompanyId}
            companyUuid={selectedCompanyUuid}
            width={730}
            changeContactDetails={changeContactDetails}
            resetContactDetails={resetContactDetails}
            {...defaultProps}
          />
        )
      )}
    </div>
  );
};
export default FullReportComponent;
