import React, { Dispatch, SetStateAction } from 'react';
import clsx from 'clsx';

import useFilesListViewModel from './useFilesListViewModel';
import FileListItem from '../FileListItem';
import styles from './styles.module.scss';
import Contact from '../../../../../../types/contact';

const FILE_ITEM_HEIGHT = 72 + 10;

interface IFilesListProps {
  isExpanded: boolean;
  setFilesCount: Dispatch<SetStateAction<number>>;
  currentContactUuid: Contact['uuid'];
}

const FilesList: React.FC<IFilesListProps> = ({
  isExpanded,
  setFilesCount,
  currentContactUuid,
}) => {
  const { filesList, itemRef } = useFilesListViewModel({
    setFilesCount,
    currentContactUuid,
  });
  const style = {
    maxHeight: isExpanded ? 'unset' : FILE_ITEM_HEIGHT,
  };
  const isOneFile = filesList.length === 1;
  const isTwoFiles = filesList.length === 2;
  const isThreeFiles = filesList.length === 3;
  const isFourFiles = filesList.length === 4;

  return (
    <div
      className={clsx(
        styles.List,
        isOneFile && styles.OneFile,
        isTwoFiles && styles.TwoFiles,
        isThreeFiles && styles.ThreeFiles,
        isFourFiles && styles.FourFiles
      )}
      style={style}
      ref={itemRef}
    >
      {filesList.map((file) => (
        <FileListItem key={file.uuid} file={file} />
      ))}
    </div>
  );
};

export default FilesList;
