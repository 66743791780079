import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import Contact from 'types/contact';
import { regexpPatterns } from 'src/utils/constants';

enum FormTypes {
  PHONE = 'number',
  EMAIL = 'email',
  URL = 'url',
  ADDRESS = 'address',
  EVENT = 'event',
}

enum FormArrayNames {
  PHONES = 'phones',
  EMAILS = 'emails',
  URLS = 'urls',
  ADDRESSES = 'addresses',
  DATES = 'dates',
}

enum EventFormSelectOptions {
  BIRTHDAY = 'birthday',
  ANNIVERSARY = 'anniversary',
  OTHER = 'other',
}

enum AddressFormSelectOptions {
  WORK = 'work',
  HOME = 'home',
  OTHER = 'other',
}

enum PhonesFormSelectOptions {
  MOBILE = 'mobile',
  HOME = 'home',
  FAX = 'work_fax',
  OTHER = 'other',
}

const getDefaultValues = (currentContact: Contact) => ({
  [FormArrayNames.PHONES]: currentContact?.phones?.map(({ type, phone }) => ({
    type,
    number: phone.trim(),
  })),
  [FormArrayNames.EMAILS]: currentContact?.emails?.length
    ? currentContact.emails.map(({ email }) => ({
        email: email.trim(),
      }))
    : [{ email: '' }],
  [FormArrayNames.URLS]: currentContact?.urls?.length
    ? currentContact.urls.map(({ url }) => ({
        url: url.trim(),
      }))
    : [{ url: '' }],
  [FormArrayNames.ADDRESSES]: currentContact?.addresses?.length
    ? currentContact.addresses.map(({ full_address, type }) => {
        return {
          full_address,
          type,
        };
      })
    : [{ full_address: '', type: AddressFormSelectOptions.WORK }],
  [FormArrayNames.DATES]: currentContact?.dates?.length
    ? currentContact.dates.map(({ month, day, year, type }) => ({
        date: new Date(year, month, day),
        type,
      }))
    : [],
});

const getFieldName = (type: FormTypes, index: number, isType?: boolean): string => {
  const _buildName = (
    arrayName: FormArrayNames,
    index: number,
    type: FormTypes,
    isType?: boolean
  ) => `${arrayName}.${index}.${isType ? 'type' : type}`;

  switch (type) {
    case FormTypes.EMAIL: {
      return _buildName(FormArrayNames.EMAILS, index, FormTypes.EMAIL, isType);
    }
    case FormTypes.ADDRESS: {
      return _buildName(FormArrayNames.ADDRESSES, index, FormTypes.ADDRESS, isType);
    }
    case FormTypes.URL: {
      return _buildName(FormArrayNames.URLS, index, FormTypes.URL, isType);
    }
    case FormTypes.EVENT: {
      return _buildName(FormArrayNames.DATES, index, FormTypes.EVENT, isType);
    }
    case FormTypes.PHONE: {
      return _buildName(FormArrayNames.PHONES, index, FormTypes.PHONE, isType);
    }
  }
};

const getFormResolver = (fullName: string) =>
  yupResolver(
    yup
      .object({
        [FormArrayNames.EMAILS]: yup.array().of(
          yup.object().shape({
            [FormTypes.EMAIL]: yup.string().email('contact-details:fields.errors.invalid_email'),
          })
        ),
        [FormArrayNames.URLS]: yup.array().of(
          yup.object().shape({
            [FormTypes.URL]: yup.string().matches(regexpPatterns.URL, {
              message: 'contact-details:fields.errors.invalid_url',
              excludeEmptyString: true,
            }),
          })
        ),
        [FormArrayNames.PHONES]: yup.array().of(
          yup.object().shape({
            [FormTypes.PHONE]: yup.string().when('emails', {
              is: (emails) => !emails?.[0]?.email && !fullName,
              then: yup
                .string()
                .test(
                  'phone-number',
                  'common:validation.phone',
                  (value) => !!value.match(/^[^a-zA-Z]+$/)
                ),
            }),
          })
        ),
        [FormArrayNames.ADDRESSES]: yup.array().of(
          yup.object().shape({
            [FormTypes.ADDRESS]: yup.string(),
          })
        ),
      })
      .required()
  );

export {
  FormTypes,
  FormArrayNames,
  EventFormSelectOptions,
  AddressFormSelectOptions,
  PhonesFormSelectOptions,
  getDefaultValues,
  getFieldName,
  getFormResolver,
};
