import { useState, SyntheticEvent, useEffect, CSSProperties } from 'react';
import { Tabs, Tab, Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'next-i18next';

import styles from 'styles/contactDetails/contactDetails.module.scss';
import ActivityTab from './ActivityTab/ActivityTab';
import FullReport from './FullReport';
import { ChangeContactDetails } from '../types';
import useRedirectEffect from 'src/app/hooks/ui/useRedirectEffect';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import { TRedirectEffect } from 'types/ui';
import Contact from '../../../../types/contact';
import { useAppDispatch } from '../../../app/hooks';
import { setSelectedReport } from 'slices/fullReportSlice';
import { useInsightsLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  style?: CSSProperties;
}

function allyProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, style, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={style}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

interface TabsMenuProps {
  changeContactDetails: ChangeContactDetails;
  resetContactDetails: () => void;
  setReportData: (d: unknown) => void;
  currentContactId: Contact['uuid'];
}

const TabsMenu: React.FC<TabsMenuProps> = ({
  changeContactDetails,
  resetContactDetails,
  setReportData,
  currentContactId,
}) => {
  const { checkLimits } = useInsightsLimits();
  const [position, setPosition] = useState(0);
  const { t } = useTranslation('contact-details');
  const redirectEffect = useRedirectEffect(RedirectEffectTargetType.COMPANY) as TRedirectEffect;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (redirectEffect && redirectEffect.targetType === RedirectEffectTargetType.COMPANY) {
      setPosition(1);
    }

    return () => {
      setPosition(0);
    };
  }, [redirectEffect]);

  const handleChange = (event: SyntheticEvent, newPosition: number) => {
    if (newPosition === 1 && !checkLimits()) return; // insight tab should be disabled for free plan
    setPosition(newPosition);

    if (newPosition === 1) {
      dispatch(setSelectedReport(0));
    }
  };

  return (
    <Box sx={{ bgcolor: '#fff' }} height={'100%'}>
      <Tabs
        classes={{ indicator: styles.tabIndicator }}
        value={position}
        onChange={handleChange}
        aria-label="contact-details-tabs"
      >
        {(t('tabs', { returnObjects: true }) as []).slice(0, 2).map((tab, index) => (
          <Tab
            classes={{
              root: styles.tab,
              wrapper: styles.tabLabel,
              selected: styles.activeTab,
            }}
            key={tab}
            label={tab}
            {...allyProps(index)}
          />
        ))}
      </Tabs>
      <TabPanel value={position} index={0}>
        <ActivityTab contactId={currentContactId} />
      </TabPanel>
      <TabPanel style={{ height: '100%' }} value={position} index={1}>
        <FullReport
          resetContactDetails={resetContactDetails}
          changeContactDetails={changeContactDetails}
          setReportData={setReportData}
        />
      </TabPanel>
      {/* <TabPanel value={position} index={2}>
        Item Three
      </TabPanel> */}
    </Box>
  );
};
export default TabsMenu;
