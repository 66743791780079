import { useTranslation } from 'next-i18next';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import clsx from 'clsx';

import { FormValues } from '../types';
import TextField from 'components/UI/Inputs/TextField';
import Button from 'components/UI/Buttons/BaseButton';
import styles from 'styles/addContact/addContacts.module.css';
import styles2 from 'styles/contactDetails/contactDetails.module.scss';
import { FormArrayNames, FormTypes, getFieldName } from './formData';

export default function WebsitesInput() {
  const { t } = useTranslation('add-contact-page');
  const { control } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray<FormValues, FormArrayNames.URLS>({
    control,
    name: FormArrayNames.URLS,
  });

  return (
    <div>
      {fields.map((item, index) => (
        <div key={item.id}>
          <Controller
            name={getFieldName(FormTypes.URL, index)}
            control={control}
            render={({ field, fieldState }) => (
              <div className={styles2.formField}>
                <TextField
                  {...field}
                  className={styles2.contactFormTitle}
                  error={!!fieldState.error}
                  helperText={t(fieldState.error?.message)}
                  label={t('duplicates:Website_field')}
                  placeholder={t('websiteHint')}
                  fullWidth
                  margin="dense"
                />

                <Button
                  className={clsx(styles2.formDeleteButton, styles2.formDeleteButtonMargin)}
                  variant="text"
                  onClick={() => {
                    remove(index);
                    index === 0 && append({ url: '' });
                  }}
                >
                  {t('duplicates:Delete_action')}
                </Button>
              </div>
            )}
          />
        </div>
      ))}
      <button
        className={styles.addFieldButton}
        type="button"
        onClick={() => {
          append({ url: '' });
        }}
      >
        {t('addAnotherWebsite')}
      </button>
    </div>
  );
}
