import { useTranslation } from 'next-i18next';
import { useAppSelector } from '../../../../app/hooks';

import AssignToSelect from '../../../../features/collaboration/components/AssignToSelect/AssignToSelect';
import {
  selectIsCurrentWorkspaceCollaborative,
  selectAcceptedTeamMembers,
} from '../../../../slices/workspacesSlice';

import useAssignContact from '../../../../features/collaboration/hooks/useAssignContact';

import styles from '../ContactCard.module.css';

export default function AssignToSection({
  contactId,
  assignedTo,
}: {
  contactId: string;
  assignedTo?: string;
}) {
  const { t } = useTranslation();

  const assignContact = useAssignContact('Main Tab');

  const isCurrentWorkspaceCollaborative = useAppSelector(selectIsCurrentWorkspaceCollaborative);
  const options = useAppSelector(selectAcceptedTeamMembers);

  if (!assignedTo || !isCurrentWorkspaceCollaborative) {
    return null;
  }

  return (
    // eslint-disable-next-line
    <div
      className={styles.assignSection}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={styles.assignSectionDivider}></div>
      <div className={styles.assignSectionSelect}>
        <span className={styles.assignSectionLabel}>{t('common:collaboration.assignee')}</span>
        <AssignToSelect
          options={options}
          value={assignedTo}
          onSelect={(email) => assignContact(contactId, email)}
        />
      </div>
    </div>
  );
}
