import Popover from '@material-ui/core/Popover';
import { InputLabel, InputBase } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import styles from 'styles/contactDetails/contactDetails.module.scss';
import { useAppDispatch, useAppSelector } from 'src/app/hooks';
import { selectCurrentContact, updateContact, updateContactById } from 'slices/contactsSlice';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

export default function EditNameForm({
  open,
  onClose,
  anchorEl,
  resultName,
  currentContact,
  setCurrentContact,
}: {
  open: boolean;
  onClose: () => void;
  anchorEl: any;
  resultName: string;
  setCurrentContact: any;
  currentContact: any;
}) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['contact-details', 'common']);
  const [editName, setEditName] = useState();
  const [editJobTitle, setEditJobTitle] = useState();
  const [editCompany, setEditCompany] = useState();
  const contact = useAppSelector(selectCurrentContact);
  const handleClose = () => {
    onClose();
    setCurrentContact(contact);
  };

  useEffect(() => {
    if (!open) {
      setEditName(undefined);
      setEditJobTitle(undefined);
      setEditCompany(undefined);
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(updateContact(currentContact));
    await dispatch(updateContactById(currentContact.uuid));
    onClose();
  };

  const handleChange = (e) => {
    switch (e.target.name) {
      case 'fullName':
        setEditName(e.target.value);

        setCurrentContact({ ...currentContact, suggestion_name: e.target.value });
        break;
      case 'job':
        setEditJobTitle(e.target.value);
        setCurrentContact({ ...currentContact, suggestion_job_title: e.target.value });
        break;
      case 'company':
        setEditCompany(e.target.value);
        setCurrentContact({ ...currentContact, suggestion_company: e.target.value });
        break;
    }
  };

  const id = open ? 'simple-popover' : undefined;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <form onSubmit={handleSubmit} className={styles.editPreviewForm}>
        <InputLabel className={styles.formLabel}>{t('editNameFields.name')}</InputLabel>
        <InputBase
          name={'fullName'}
          onChange={handleChange}
          className={styles.formInput}
          value={editName ?? resultName}
          inputProps={{ 'data-hj-allow': '' }}
        ></InputBase>
        <InputLabel className={styles.formLabel}>{t('editNameFields.job')}</InputLabel>
        <InputBase
          value={editJobTitle ?? (currentContact.suggestion_job_title || currentContact.job_title)}
          name={'job'}
          onChange={handleChange}
          className={styles.formInput}
          inputProps={{ 'data-hj-allow': '' }}
        />
        <InputLabel className={styles.formLabel}>{t('editNameFields.company')}</InputLabel>
        <InputBase
          value={editCompany ?? (currentContact.suggestion_company || currentContact.company)}
          name={'company'}
          onChange={handleChange}
          className={styles.formInput}
          inputProps={{ 'data-hj-allow': '' }}
        />
        <div className={styles.formActions}>
          <Button className={styles.succeedButton} type={'submit'}>
            {t('duplicates:Save_action')}
          </Button>
          <Button
            className={styles.cancelButton}
            type={'button'}
            onClick={handleClose}
            disabled={false}
          >
            {t('duplicates:Cancel_action')}
          </Button>
        </div>
      </form>
    </Popover>
  );
}
