import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Button from '../../Buttons/BaseButton';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { itemColors } from '../../../../utils/constants';
import Spinner from '../../../../components/UI/Spinner';
import PaletteForm from '../../PaletteForm/PaletteForm';

import styles from './ColumnAttributesForm.module.css';

export type ColumnAttributesFormProps = {
  open: boolean;
  defaultColor?: string;
  inputDefaultValue?: string;
  inputPlaceholder?: string;
  saveButtonText?: string;
  onSubmit: ({ title, color }: { title: string; color: string }, c?: boolean) => Promise<void>;
  onClose: () => void;
  className?: string;
};

const ColumnAttributesForm = ({
  open,
  defaultColor,
  inputDefaultValue = '',
  inputPlaceholder,
  saveButtonText,
  onClose,
  onSubmit,
  className = '',
}: ColumnAttributesFormProps) => {
  const [submitting, setSubmitting] = useState(false);

  const { t } = useTranslation(['common']);

  const [choosedColor, setChoosedColor] = useState(defaultColor || itemColors[0]);
  const [title, setTitle] = useState(inputDefaultValue);

  const handleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      await onSubmit({ title, color: choosedColor });
    } finally {
      setSubmitting(false);
    }
  };

  const onColorSaveClick = async (color) => {
    setSubmitting(true);

    try {
      setChoosedColor(color);
      await onSubmit({ title, color }, false);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    open && (
      <ClickAwayListener onClickAway={handleClose}>
        <div className={clsx(styles.ColumnForm, className)}>
          <Paper className={styles.ColumnFormContainer}>
            <InputBase
              className={styles.ColumnFormInput}
              placeholder={inputPlaceholder}
              onChange={handleChange}
              value={title}
              disabled={submitting}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
              endAdornment={
                <PaletteForm defaultColor={choosedColor} onColorPick={onColorSaveClick} />
              }
              inputProps={{ 'data-hj-allow': '' }}
            />
            <div className={styles.ColumnFormActions}>
              <div className={styles.ColumnFormLoaderPlace}>
                <Button
                  onClick={handleSubmit}
                  disabled={!title.length || submitting}
                  textSize="small"
                  className={clsx(styles.saveButton, submitting && styles.submitting)}
                >
                  {saveButtonText || t('save')}
                </Button>
                {submitting && (
                  <div className={styles.ColumnFormActionsSpinnerWrapper}>
                    <Spinner size={25} className={styles.ColumnFormActionsSpinner} />
                  </div>
                )}
              </div>
              <Button onClick={handleClose} variant="text" color="secondary" textSize="small">
                {t('duplicates:Cancel_action')}
              </Button>
            </div>
          </Paper>
        </div>
      </ClickAwayListener>
    )
  );
};
export default ColumnAttributesForm;
