import { useTranslation } from 'next-i18next';
import { DragEventHandler, useContext, useRef, useState } from 'react';

import FilesContext from '../../../domain/context/FilesContext';
import { useInjectionWithRef } from '../../../../../app/ioc/ioc.react';
import { IAmplitudeManager } from '../../../../../analytics/amplitude';
import { TYPES as GLOBAL_TYPES } from '../../../../../app/ioc/types';
import { useFilesLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

const useFilesDnDViewModel = () => {
  const { t } = useTranslation('files');
  const { checkLimits } = useFilesLimits();

  const Amplitude = useInjectionWithRef<IAmplitudeManager>(GLOBAL_TYPES.AmplitudeManager);
  const [isDragActive, setIsDragActive] = useState<boolean>(false);
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const { addItemToQueue } = useContext(FilesContext);

  const formattedInstructionText = t('dnd_area.instruction', {
    browseText: t('dnd_area.browse_text'),
  }).replace(t('dnd_area.browse_text'), `<span>${t('dnd_area.browse_text')}</span>`);

  const onAddButtonClick = () => {
    if (checkLimits() && !isDragActive) {
      Amplitude.trackEvent('Add_file_tapped');
      hiddenInputRef.current.click();
    }
  };

  const handleDrag: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragActive(true);
    } else if (e.type === 'dragleave') {
      setIsDragActive(false);
    }
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
    if (!checkLimits()) return;

    const files = e.dataTransfer.files;

    if (files.length) {
      addItemToQueue(Array.prototype.slice.call(files));
    }
  };

  return {
    handleDrag,
    handleDrop,
    onAddButtonClick,
    formattedInstructionText,
    hiddenInputRef,
    isDragActive,
  };
};

export default useFilesDnDViewModel;
