import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import styles from '../../styles/contactDetails/contactDetails.module.scss';
import TagsDialog from '../../components/UI/Dialogs/TagDialog/TagsDialog';
import { useAppSelector } from 'src/app/hooks';
import { selectCurrentContact } from 'slices/contactsSlice';
import { selectContactTags } from 'slices/tagsBoardSlice';

const Tags = () => {
  const { t } = useTranslation('contact-details');
  const currentContact = useAppSelector(selectCurrentContact);
  const tags = useAppSelector(selectContactTags(currentContact.uuid));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    // eslint-disable-next-line
    <div onClick={handleClick} className={styles.tagsContainer}>
      {tags?.map((tag) => (
        <span className={styles.tag} key={tag.id} style={{ backgroundColor: tag.color }}>
          {tag.title}
        </span>
      ))}
      {currentContact.tags?.length ? (
        <button className={styles.tagButton}>{t('tagButton.edit')}</button>
      ) : (
        <button className={styles.tagButton}>{t('tagButton.add')}</button>
      )}
      <TagsDialog
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        contactId={currentContact.uuid}
        action_type={'Contact details'}
      />
    </div>
  );
};
export default Tags;
