import React from 'react';
import useCustomFieldsListViewModel from './CustomFieldsListViewModel';
import CustomField from '../CustomField';

interface Props {
  contactUuid: string;
}

const CustomFieldsList: React.FC<Props> = ({ contactUuid }) => {
  const { list, valuesMap } = useCustomFieldsListViewModel(contactUuid);

  return (
    <div>
      {list.map((field) => (
        <CustomField
          contactUuid={contactUuid}
          field={field}
          value={valuesMap[field.uuid]}
          key={field.uuid}
        />
      ))}
    </div>
  );
};

export default CustomFieldsList;
