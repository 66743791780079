import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';

import { useAppDispatch } from '../../../../../app/hooks';
import DeleteDialog from '../../../../../components/UI/Dialogs/DeleteDialog';
import styles from './Tasks.module.css';

import { useFormat } from '../../../../../lib/date-fns';

import StatusSwitcher from '../../../../tasks/components/StatusSwitcher/StatusSwitcher';
import ImportanceSwitcher from '../../../../tasks/components/ImportanceSwitcher/ImportanceSwitcher';

import DueBadge from '../../../../tasks/components/TimeBadge/DueBadge';
import ReminderBadge from '../../../../tasks/components/TimeBadge/ReminderBadge';
import useTaskDrawer from 'src/features/tasks/TaskDrawer/useTaskDrawer';
import { deleteTasks } from 'src/slices/tasksSlice/tasksSlice';
import { MessageType } from 'components/UI/snackbar/providers/SnackBarProvider';
import TaskEntity from 'src/db/entities/task/TaskEntity';
import { trackEvent } from 'src/analytics/amplitude';

const TaskItem = ({ task }: { task: TaskEntity }) => {
  const { t } = useTranslation('contact-details');
  const { createdAt, updatedAt } = task;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const { openUpdateForm } = useTaskDrawer();
  const format = useFormat();
  const [updating, setUpdating] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const descriptionHyphenationIndex = !!task.description && task.description.indexOf('\n');
  const shortDescription =
    task.description && descriptionHyphenationIndex !== -1
      ? task.description.slice(0, descriptionHyphenationIndex)
      : task.description;
  const isTaskUpdated = updatedAt > createdAt;
  const timeFormatted = format(isTaskUpdated ? updatedAt : createdAt, 'iii d MMM HH:mm');

  const deleteTask = async () => {
    try {
      setDeleting(true);
      trackEvent('Delete_task');
      await dispatch(deleteTasks([task.id])).unwrap();

      enqueueSnackbar({
        message: t('tasks.delete.notification_deleted'),
        variant: MessageType.Success,
      });
    } catch (error) {
      const message = !error.response?.status
        ? t('common:message.network_error')
        : t('common:message.server_error');

      enqueueSnackbar({
        message,
        variant: MessageType.Error,
      });
    } finally {
      setDeleting(false);
    }
  };

  return (
    <>
      <div key={task.id} className={styles.taskItem}>
        <div className={styles.taskTopContent}>
          <StatusSwitcher
            id={task.id}
            status={task.status}
            disabled={deleting}
            onUpdateStart={() => setUpdating(true)}
            onUpdateEnd={() => setUpdating(false)}
            className={styles.status}
          />
          <div className={styles.taskMainInfo}>
            <div className={styles.taskTitle}>
              {task.title}
              <ImportanceSwitcher
                id={task.id}
                important={task.important}
                disabled={deleting}
                className={styles.taskImportance}
                onUpdateStart={() => setUpdating(true)}
                onUpdateEnd={() => setUpdating(false)}
              />
            </div>
            {(task.dueDate || task.reminder?.date) && (
              <div className={styles.taskBadges}>
                {task.dueDate && (
                  <DueBadge
                    timestamp={task.dueDate}
                    completed={task.status === 'completed'}
                    repeat={task.repeat}
                    className={styles.badge}
                  />
                )}
                <ReminderBadge reminder={task.reminder} className={styles.badge} />
              </div>
            )}
            {shortDescription && <p className={styles.taskDescription}>{shortDescription}</p>}
          </div>
        </div>
        <div className={styles.taskBottomContent}>
          <span className={styles.date}>
            {t(isTaskUpdated ? 'tasks.updated_on' : 'tasks.created_on')} {timeFormatted}
          </span>
          <button
            disabled={updating || deleting}
            onClick={() => openUpdateForm({ id: task.id })}
            className={styles.taskAction}
          >
            {t('duplicates:Edit_action')}
          </button>
          <button
            disabled={updating || deleting}
            onClick={() => {
              setOpenDialog(true);
            }}
            className={styles.taskAction}
          >
            {t('duplicates:Delete_action')}
          </button>
        </div>
      </div>
      <DeleteDialog
        title={t('tasks.delete.title')}
        description={t('tasks.delete.description')}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onSuccess={deleteTask}
      />
    </>
  );
};
export default TaskItem;
