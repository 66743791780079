import { v4 as uuidv4 } from 'uuid';
import CallEntity from '../../../../db/entities/call/CallEntity';

import { AppState } from '../../../../app/store/store';
import { useAppDispatch, useAppStore } from '../../../../app/hooks';
import { useProposalDrawer } from '../../../proposals/ProposalDrawer';
import {
  createContact,
  selectContactsArray,
  filterByAnchors,
} from '../../../../slices/contactsSlice';
import { selectCalls } from '../../../../slices/callsSlice';
import { selectUserProfile } from '../../../../slices/userSlice';
import { MessageType } from '../../../../components/UI/snackbar/providers/SnackBarProvider';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'next-i18next';

import useDuplicateContact from '../../../../features/contacts/hooks/useDuplicateContact';
import Contact from '../../../../../types/contact';

function useCreateProposalFromCall(type: string) {
  const { t } = useTranslation('calls-page');
  const { enqueueSnackbar } = useSnackbar();

  const store = useAppStore();
  const dispatch = useAppDispatch();

  const duplicateContact = useDuplicateContact();

  const { openCreateForm, close } = useProposalDrawer();

  // this function will find related contact or create / duplicate existed if necessary
  const getRelatedContactId = (call: CallEntity): Contact['id'] => {
    const state = store.getState() as AppState;

    const { email } = selectUserProfile(state);
    const contacts = selectContactsArray(state);

    const relatedContact = filterByAnchors(contacts).find((contactItem) =>
      contactItem.phones.some((phoneItem) => phoneItem.normalized_phone === call.normalizedPhone)
    );

    if (!relatedContact) {
      const id = uuidv4();
      dispatch(
        createContact({
          uuid: id,
          workspace_id: state.workspaces.current,
          assigned_to: email,
          name: call.callerInfo.name,
          job_title: call.callerInfo.jobTitle,
          is_spammer: !!call.callerInfo.spam,
          thumbnail: call.callerInfo.thumbnail,
          phones: [
            {
              type: 'mobile',
              phone: call.phoneNumber,
              custom_label: null,
              normalized_phone: call.normalizedPhone,
            },
          ],
        })
      )
        .unwrap()
        .catch(() => {
          close();
          enqueueSnackbar({
            message: t('common:error.contactСreationFailed'),
            variant: MessageType.Error,
          });
        });

      return id;
    } else {
      if (relatedContact.workspace_id === null) {
        const id = uuidv4();

        duplicateContact(relatedContact.uuid, { uuid: id }).catch(() => {
          close();
          enqueueSnackbar({
            message: t('common:error.contactСreationFailed'),
            variant: MessageType.Error,
          });
        });

        return id;
      } else {
        return relatedContact.uuid;
      }
    }
  };

  return (callUuid: string) => {
    const state = store.getState();
    const calls = selectCalls(state);

    const call = calls.find((feedItem) => feedItem.id === callUuid);

    const relatedContactId = getRelatedContactId(call);

    openCreateForm({
      defaultValues: { contactId: relatedContactId },
      onSuccess: close,
      shouldPinContact: true,
      type,
    });
  };
}

export default useCreateProposalFromCall;
