import clsx from 'clsx';
import styles from './BoardCard.module.css';

export default function BoardCard(
  props: {
    children: React.ReactNode;
    onClick?: () => void;
  } & React.HTMLAttributes<HTMLDivElement>
) {
  const { children, className, onClick, ...rest } = props;

  return (
    <div
      onKeyDown={onClick}
      onClick={onClick}
      tabIndex={0}
      role={'button'}
      className={clsx(styles.wrapper, className)}
      {...rest}
    >
      {children}
    </div>
  );
}
