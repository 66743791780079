import { MouseEventHandler, useContext, useState } from 'react';

import { useInjection, useInjectionWithRef } from '../../../../../app/ioc/ioc.react';
import { TYPES } from '../../../di/types';
import FileBC from '../../../domain/FileBC';
import usePreparedDialogs from '../../../../../app/hooks/ui/usePreparedDialogs';
import DialogTypes from '../../../../../enums/DialogTypes';
import { shortWithMiddleEllipsis } from '../../../../../utils/stringUtils';
import { IFileRenameDialogProps } from 'components/UI/Dialogs/FileRenameDialog';
import { IFileRemovingDialogProps } from 'components/UI/Dialogs/FileRemovingDialog';
import FilesContext from '../../../domain/context/FilesContext';
import { IFilesUseCases } from '../../../domain/abstractions/IFilesUseCases';
import { IAmplitudeManager } from '../../../../../analytics/amplitude';
import { TYPES as GLOBAL_TYPES } from '../../../../../app/ioc/types';

const useFileListItemViewModel = (file: FileBC) => {
  const { deleteFile, downloadFile } = useInjection<IFilesUseCases>(TYPES.FilesUseCases);
  const Amplitude = useInjectionWithRef<IAmplitudeManager>(GLOBAL_TYPES.AmplitudeManager);
  const { showDialog, hideDialog } = usePreparedDialogs();
  const { setPreviewFile, commonFilesList, setCommonFilesList } = useContext(FilesContext);
  const [fileMenuAnchorEl, setFileMenuAnchorEl] = useState<HTMLButtonElement>(null);
  const { name } = file;

  const deleteFileOptimistic = () => {
    const updatedList = commonFilesList.filter((item) => item.uuid !== file.uuid);

    setCommonFilesList(updatedList);
  };

  const onDeleteFile = () => {
    setFileMenuAnchorEl(null);
    showDialog({
      type: DialogTypes.FILE_REMOVING_DIALOG,
      dialogProps: {
        fileName: shortWithMiddleEllipsis(name),
        onClose: () => hideDialog(DialogTypes.FILE_REMOVING_DIALOG),
        onDelete: () => {
          Amplitude.trackEvent('Delete_file');
          deleteFileOptimistic();
          hideDialog(DialogTypes.FILE_REMOVING_DIALOG);
          deleteFile(file.uuid).subscribe();
        },
      } as IFileRemovingDialogProps,
    });
  };

  const onRenameDialog = () => {
    setFileMenuAnchorEl(null);
    showDialog({
      type: DialogTypes.FILE_RENAME_DIALOG,
      dialogProps: {
        isOpen: true,
        onClose: () => hideDialog(DialogTypes.FILE_RENAME_DIALOG),
        onRename: () => {
          Amplitude.trackEvent('Rename_file');
        },
        file,
      } as IFileRenameDialogProps,
    });
  };

  const onDownloadFile = () => {
    Amplitude.trackEvent('Download_file');
    downloadFile(file);
    onCloseFileMenu();
  };

  const onFileClick = () => {
    Amplitude.trackEvent('Preview_file');
    setPreviewFile(file);
  };

  const onMoreButtonClick: MouseEventHandler<HTMLButtonElement> = ({ currentTarget }) => {
    setFileMenuAnchorEl(currentTarget);
  };

  const onCloseFileMenu = () => setFileMenuAnchorEl(null);

  return {
    onDeleteFile,
    onDownloadFile,
    onRenameDialog,
    onFileClick,
    onMoreButtonClick,
    onCloseFileMenu,
    fileMenuAnchorEl,
  };
};

export default useFileListItemViewModel;
