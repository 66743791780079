import React, { useState, useEffect } from 'react';
import DatePicker, { CalendarContainer } from 'react-datepicker';
import { format } from 'date-fns';
import DateInput from './Input';
import clsx from 'clsx';
import DatePickerHeader from './DatePickerHeader';
import { ClickAwayListener } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import styles from './styles/Container.module.css';
const DatePickerContainer = ({ className, children }) => {
  return (
    <div className={styles.wrapper}>
      <CalendarContainer className={className}>{children}</CalendarContainer>
    </div>
  );
};
const DatePickerInput = ({ value, onChange, updateView }) => {
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  useEffect(() => {
    updateView && updateView();
  }, [openDatePicker, updateView]);

  if (!value) return null;

  const handleClick = (e) => {
    e.preventDefault();
    setOpenDatePicker(!openDatePicker);
  };
  const handleClickAway = () => {
    setOpenDatePicker(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={clsx(styles.container, openDatePicker && styles.containerShow)}>
        <DateInput
          onClick={handleClick}
          value={format(value, 'dd MMMM yyyy')}
          openDatePicker={openDatePicker}
        />
        {openDatePicker && (
          <DatePicker
            className={styles.wrapper}
            formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
            selected={value}
            onChange={(value) => {
              onChange(value);
              setOpenDatePicker(!openDatePicker);
            }}
            inline
            calendarContainer={DatePickerContainer}
            renderCustomHeader={(props) => <DatePickerHeader {...props} />}
          />
        )}
      </div>
    </ClickAwayListener>
  );
};

export default DatePickerInput;
