import { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Popover, ClickAwayListener, Button } from '@material-ui/core';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CreateTagPopup from '../CreateTagPopup/CreateTagPopup';
import Spinner from 'components/UI/Spinner';

import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { selectTagsBoard, addTagThunk, deleteItems } from '../../../../slices/tagsBoardSlice';

import useAttachContactToTag from '../../../../features/contacts/hooks/useAttachContactToTag';
import { selectIsAdminOrOwner } from '../../../../slices/workspacesSlice';
import styles from './TagDialog.module.css';
import { trackEvent } from 'src/analytics/amplitude';
import useFBAnalytics from 'src/analytics/FB/ui/useFBAnalytics';
import FB_EVENTS from 'src/analytics/FB/domain/enums/FB_EVENTS';

//TODO: this dialog should be moved to src/features/contacts, because it contains bussines logic
const TagsDialog = ({ anchorEl, setAnchorEl, contactId, action_type }) => {
  const { t } = useTranslation('contacts-page');
  const [tags, setTags] = useState<{ color: string; title: string; id: string }[]>([]);
  const [selectedTags, setSelectedTags] = useState<{ columnId: string; tagItemId: string }[]>([]);
  const open = !!anchorEl;
  const [openForm, setOpenForm] = useState(false);
  const isAdmin = useAppSelector(selectIsAdminOrOwner);
  const tagsBoard = useAppSelector(selectTagsBoard);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);
  const [addTagAnchorEl, setAddTagAnchorEl] = useState(null);
  const { trackEvent: FBTrackEvent } = useFBAnalytics();
  const handleAddTag = async (title, color) => {
    setLoading(true);
    await dispatch(addTagThunk({ title, color }));
    setLoading(false);
    setOpenForm(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const attachContactToTag = useAttachContactToTag();

  const handleManageContact = async (e, tagId: string) => {
    const existedTag = selectedTags.find(({ columnId }) => columnId === tagId);
    if (!selectedTags.length) {
      trackEvent('Add_first_tag', { action: action_type });
    } else {
      trackEvent('Edit_tag', { action: action_type });
    }
    if (existedTag) {
      dispatch(
        deleteItems({
          columnUuid: existedTag.columnId,
          itemsUuids: [existedTag.tagItemId],
        })
      );
    } else {
      FBTrackEvent('trackCustom', FB_EVENTS.ADD_LABEL);
      attachContactToTag({
        contactId,
        tagId,
      });
    }
  };

  useEffect(() => {
    const contactSelectedTags = [];
    tagsBoard?.columns.forEach(({ id, items }) => {
      items.forEach((item) => {
        item?.itemTypeId === contactId &&
          contactSelectedTags.push({ columnId: id, tagItemId: item.id });
      });
    });
    setSelectedTags(contactSelectedTags);
  }, [contactId, tagsBoard]);

  useEffect(() => {
    const mapTagsBoardToTags =
      tagsBoard?.columns.map(({ color, title, id }) => {
        return {
          color,
          title,
          id,
        };
      }) ?? [];
    setTags(mapTagsBoardToTags);
  }, [tagsBoard]);

  return (
    <Popover
      disablePortal
      classes={{ paper: styles.dialogWrapper, root: styles.rootWrapper }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 40,
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <div className={clsx(styles.container, styles.containerTagsDialog)}>
          {tags?.map((tag) => (
            <button
              key={tag.id}
              className={styles.tagItem}
              onClick={(e) => handleManageContact(e, tag.id)}
            >
              <button style={{ background: tag.color }} className={styles.tagColor}>
                {selectedTags.some(({ columnId }) => columnId == tag.id) && <CheckOutlinedIcon />}
              </button>
              <span className={styles.tagNameInput}>{tag.title}</span>
            </button>
          ))}
          {isAdmin && (
            <Button
              onClick={({ currentTarget }) => {
                setAddTagAnchorEl(currentTarget);
                setOpenForm(!openForm);
              }}
              className={styles.addTagButton}
            >
              + {t('tagForm.add.title')}
            </Button>
          )}
          {openForm && (
            <CreateTagPopup
              anchorEl={addTagAnchorEl}
              onClose={() => setAddTagAnchorEl(null)}
              onSubmit={handleAddTag}
            />
          )}
          {loading && (
            <div className={styles.spinnerWrapper}>
              <Spinner size={50} />
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Popover>
  );
};
export default TagsDialog;
