import { useEffect, useState } from 'react';
import { useObservable, useSubscription } from 'src/app/hooks/rx';
import { useInjection } from 'src/app/ioc/ioc.react';
import CustomFieldEntity from 'src/db/entities/customField/CustomFieldEntity';
import CustomFieldValueEntity from 'src/db/entities/customFieldValue/CustomVieldValueEntity';
import { CUSTOM_FIELDS_TYPES } from 'src/features2/common/customField/di/types';
import { ICustomFieldUseCase } from 'src/features2/common/customField/domain/abstractions/ICustomFieldUseCase';
import { CUSTOM_FIELD_VALUE_TYPES } from 'src/features2/common/customFieldValue/di/types';
import { ICustomFieldValueUseCase } from 'src/features2/common/customFieldValue/domain/abstractions/ICustomFieldValueUseCase';

const useCustomFieldsListViewModel = (contactUuid: string) => {
  const [list, setList] = useState<CustomFieldEntity[]>([]);
  const [valuesList, setValuesList] = useState<CustomFieldValueEntity[]>([]);
  const [valuesMap, setValuesMap] = useState<Record<string, CustomFieldValueEntity>>({});
  const customFieldUseCase = useInjection<ICustomFieldUseCase>(
    CUSTOM_FIELDS_TYPES.CustomFieldUseCase
  );
  const customFieldsValuesUseCase = useInjection<ICustomFieldValueUseCase>(
    CUSTOM_FIELD_VALUE_TYPES.CustomFieldValueUseCase
  );
  const customFieldsObservable = useObservable(() => customFieldUseCase.getAll(), []);
  const customFieldsValuesObservable = useObservable(
    () => customFieldsValuesUseCase.getAll(contactUuid),
    [contactUuid]
  );
  useSubscription(customFieldsObservable, (fields) => {
    setList(fields);
  });
  useSubscription(customFieldsValuesObservable, (fields) => {
    setValuesList(fields);
  });

  useEffect(() => {
    const map = {};

    valuesList.forEach((value) => {
      map[value.customFieldUuid] = value;
    });

    setValuesMap(map);
  }, [valuesList]);

  return {
    list,
    valuesMap,
  };
};

export default useCustomFieldsListViewModel;
