import clsx from 'clsx';
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Slide, Paper, Divider } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';

import Tags from './Tags';
import Actions from './Actions';
import Tabs from './Tabs';
import EditName from './EditName';
import ContactFields from './ContactFields';
import {
  selectCurrentContact,
  setCurrentContact,
  setField,
  updateContactById,
} from 'slices/contactsSlice';
import styles from '../../styles/contactDetails/contactDetails.module.scss';
import Avatar from './Avatar';
import {
  selectIsCompanyReportOpened,
  setIsCompanyReportOpened,
  selectContactDetailsData,
  setContactDetailsData,
  selectReportHistory,
  setReportHistory,
  setSelectedCompanyId,
  setSelectedCompanyUuid,
  setSelectedPersonUuid,
  setSelectedPersonEmail,
  setSelectedPersonPhoneNumber,
  setSelectedReport,
  selectSelectedReport,
} from 'slices/fullReportSlice';
import ActionSelects from './ActionSelects';
import SimilarContacts from './RelatedContacts';
import contactService from 'src/services/contacts/contactService';
import useRedirectEffect from 'src/app/hooks/ui/useRedirectEffect';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import { TRedirectEffect } from 'types/ui';
import { externalSetIsColumnExpanded } from 'components/UI/CallsContactsColumn';
import { useAppSelector } from '../../app/hooks';
import { setRedirectEffect } from 'slices/uiSlice';
import DirectedText from '../../features2/localization/ui/DirectedText';
import { ManageFieldsButton } from '../../features2/settings/fieldsManagement';

enum ReportType {
  PERSON,
  COMPANY,
}

const ContactDetails = ({ className }: { className?: string }) => {
  const [openForm, setOpenForm] = useState(false);
  const [reportData, setReportData] = useState(null);
  const formEl = useRef<HTMLDivElement>(null);
  const tabsContainerRef = useRef(null);
  const currentContact = useAppSelector(selectCurrentContact);
  const isVisible = !!currentContact;
  const { t } = useTranslation('contact-details');
  const dispatch = useDispatch();
  const router = useRouter();
  const isCompanyReportOpened = useAppSelector(selectIsCompanyReportOpened);
  const contactDetailsData = useAppSelector(selectContactDetailsData);
  const [localContact, setLocalContact] = useState(currentContact);
  const reportHistory = useAppSelector(selectReportHistory);
  const selectedReport = useAppSelector(selectSelectedReport);
  const isPersonReport = selectedReport === ReportType.PERSON;
  const targetHistoryItem = reportHistory[reportHistory.length - 2];
  const redirectEffect = useRedirectEffect(RedirectEffectTargetType.COMPANY) as TRedirectEffect;
  const contactPhoneNumber = currentContact?.phones?.[0]?.phone;
  const isWithoutPhoneNumber = contactPhoneNumber === undefined || contactPhoneNumber === '';

  useEffect(() => {
    setLocalContact(currentContact);
  }, [currentContact]);

  useEffect(() => {
    window.onpopstate = () => {
      dispatch(setCurrentContact(null));
      history.go(1);
    };
    return () => {
      window.onpopstate = () => {};
    };
  }, [router, dispatch]);

  const getJobTitle = () => {
    const jobTitle = localContact?.suggestion_job_title || localContact?.job_title || '';
    const companyTitle =
      (localContact?.suggestion_job_title || localContact?.job_title) &&
      (localContact?.suggestion_company || localContact?.company)
        ? t('job_title', {
            company: localContact?.suggestion_company || localContact?.company,
          })
        : '';

    return `${jobTitle} ${companyTitle}`;
  };
  const initialContactDetailsData = {
    contactTitle: contactService.getContactName(localContact),
    contactJobTitle: getJobTitle(),
    companyImageUrl: contactService.getContactImage(currentContact),
    companyEmployees: null,
    companyIndustry: null,
    companyHeadquarters: null,
    companyFoundedYear: null,
    companyContactEmail: null,
    companyWebsite: null,
    contactAge: null,
    contactNumber: null,
  };

  useEffect(() => {
    if (currentContact && reportData) {
      const currentContactEmail = Array.isArray(currentContact.emails)
        ? currentContact.emails[0]?.email
        : undefined;

      const reportDataEmails = (reportData?.contacts?.emails || []) as string[];
      const isCurrentContactReport =
        currentContactEmail && reportDataEmails?.includes(currentContactEmail);

      if (isCurrentContactReport) {
        const contactHasNoJobTitle =
          !currentContact?.job_title && !currentContact?.suggestion_job_title;

        if (contactHasNoJobTitle) {
          dispatch(
            setField({ type: 'suggestion_job_title', value: reportData?.experience?.[0].industry })
          );
          dispatch(updateContactById(currentContact?.uuid));
        }
      }
    }
  }, [currentContact, reportData]);

  const {
    contactTitle,
    contactJobTitle,
    companyImageUrl,
    companyEmployees,
    companyIndustry,
    companyHeadquarters,
    companyFoundedYear,
    companyContactEmail,
    companyWebsite,
    contactNumber,
  } = contactDetailsData || initialContactDetailsData;

  const isReportBreadcrumbsVisible = reportHistory.length > 1;

  const handleClose = () => {
    if (window.innerWidth <= 1280) {
      externalSetIsColumnExpanded(true);
    }

    if (redirectEffect && redirectEffect?.targetType === RedirectEffectTargetType.COMPANY) {
      dispatch(setRedirectEffect(null));
      dispatch(setReportHistory([]));
    }

    dispatch(setCurrentContact(null));
  };
  const handleEdit = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);

  const changeContactDetails = (data) => {
    const {
      title,
      jobTitle,
      image,
      contactEmail,
      website,
      founded,
      employees,
      headquarters,
      age,
      isCompanyReport,
      isPersonReport,
      personEmail,
      personUuid,
      companyUuid,
      personPhoneNumber,
      companyId,
      contactNumber,
    } = data;

    dispatch(setIsCompanyReportOpened(true));
    dispatch(
      setContactDetailsData({
        contactTitle: title,
        contactJobTitle: jobTitle?.split(',')[0],
        companyImageUrl: image,
        companyEmployees: employees,
        companyIndustry: jobTitle,
        companyHeadquarters: headquarters,
        companyFoundedYear: [{ year: founded }],
        companyContactEmail: [contactEmail],
        companyWebsite: [website],
        contactAge: age,
        contactNumber: contactNumber
          ? [contactNumber]?.map((phone) => ({ phone, normalized_phone: phone }))
          : null,
      })
    );

    if (reportHistory[reportHistory?.length - 1]?.title !== title) {
      dispatch(
        setReportHistory([
          ...reportHistory,
          {
            title,
            isCompanyReport,
            isPersonReport,
            personEmail,
            personUuid,
            companyUuid,
            personPhoneNumber,
            companyId,
          },
        ])
      );
    }
  };

  useEffect(() => {
    if (redirectEffect && redirectEffect.targetType === RedirectEffectTargetType.COMPANY) {
      dispatch(setSelectedCompanyId(redirectEffect.targetId));
      dispatch(setSelectedReport(ReportType.COMPANY));
    }

    return () => {
      dispatch(setSelectedCompanyId(''));
      dispatch(setSelectedReport(undefined));
    };
  }, [redirectEffect, dispatch]);

  const resetContactDetails = () => {
    dispatch(setIsCompanyReportOpened(false));
    dispatch(setContactDetailsData(null));
  };

  const handleReportsNavigation = () => {
    const { isCompanyReport, isPersonReport } = targetHistoryItem;

    if (isCompanyReport) {
      const { companyId, companyUuid } = targetHistoryItem;

      dispatch(setSelectedCompanyId(companyId));
      dispatch(setSelectedCompanyUuid(companyUuid));
      dispatch(setSelectedReport(ReportType.COMPANY));

      dispatch(setReportHistory(reportHistory?.slice(0, reportHistory?.length - 1)));
    } else if (isPersonReport) {
      const { personUuid, personEmail, personPhoneNumber } = targetHistoryItem;

      dispatch(setSelectedPersonUuid(personUuid));
      dispatch(setSelectedPersonPhoneNumber(personPhoneNumber));
      dispatch(setSelectedPersonEmail(personEmail));
      dispatch(setSelectedReport(ReportType.PERSON));

      dispatch(setReportHistory(reportHistory?.slice(0, reportHistory?.length - 1)));
    }
  };

  return currentContact?.uuid ? (
    <Slide direction={'left'} in={isVisible} mountOnEnter unmountOnExit>
      <div className={clsx(styles.wrapper, className)} key={currentContact.uuid}>
        <Paper className={styles.paper}>
          <div className={styles.container}>
            <div className={styles.backButtonContainer}>
              <button onClick={isReportBreadcrumbsVisible ? handleReportsNavigation : handleClose}>
                <ArrowBackIcon />{' '}
                <DirectedText>
                  {isReportBreadcrumbsVisible
                    ? t('backButton', { pageTitle: targetHistoryItem.title })
                    : t('backButton', {
                        pageTitle: router.pathname.includes('calls')
                          ? t('common:sidebar.calls_button')
                          : t('duplicates:Contacts_title'),
                      })}
                </DirectedText>
              </button>
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.mainInfo}>
                <div className={styles.previewInfo}>
                  <div className={styles.ImageTitlesWrapper}>
                    <Avatar imageUrl={companyImageUrl} />
                    <div className={styles.contactTitles} ref={formEl}>
                      <button
                        style={{ all: 'unset' }}
                        onClick={!isCompanyReportOpened ? handleEdit : null}
                      >
                        <h2 className={styles.contactMainTitle}>{contactTitle}</h2>
                        <span className={styles.contactJobTitle}>{contactJobTitle}</span>
                      </button>
                      {!isCompanyReportOpened && <Tags />}
                      <Actions
                        isPersonReportOpened={isPersonReport}
                        isCompanyReportOpened={isCompanyReportOpened}
                        emails={companyContactEmail}
                        currentContact={currentContact}
                        isWithoutPhoneNumber={isWithoutPhoneNumber}
                      />
                      <ActionSelects reportData={reportData} />
                      {!isCompanyReportOpened && (
                        <EditName
                          currentContact={localContact}
                          setCurrentContact={setLocalContact}
                          open={openForm}
                          onClose={handleCloseForm}
                          anchorEl={formEl.current}
                          resultName={contactService.getContactName(localContact)}
                        />
                      )}
                    </div>
                  </div>
                  {/*<Actions*/}
                  {/*  isPersonReportOpened={isPersonReport}*/}
                  {/*  isCompanyReportOpened={isCompanyReportOpened}*/}
                  {/*  emails={companyContactEmail}*/}
                  {/*  phones={contactNumber}*/}
                  {/*  currentContact={currentContact}*/}
                  {/*  isWithoutPhoneNumber={isWithoutPhoneNumber}*/}
                  {/*/>*/}
                  {/*<ActionSelects reportData={reportData} />*/}
                </div>
                <Divider />
                <div className={styles.fieldsWrapper}>
                  <ContactFields
                    websites={companyWebsite}
                    emails={companyContactEmail}
                    foundedYear={companyFoundedYear}
                    companyEmployees={companyEmployees}
                    companyIndustry={companyIndustry}
                    companyHeadquarters={companyHeadquarters}
                    isReport={isCompanyReportOpened}
                    phones={contactNumber}
                    isWithoutPhoneNumber={isWithoutPhoneNumber}
                  />
                </div>
                <SimilarContacts />
                <ManageFieldsButton />
              </div>
              <div ref={tabsContainerRef} className={styles.tabsContainer}>
                <Tabs
                  resetContactDetails={resetContactDetails}
                  changeContactDetails={changeContactDetails}
                  setReportData={setReportData}
                  currentContactId={currentContact.uuid}
                />
              </div>
            </div>
          </div>
        </Paper>
      </div>
    </Slide>
  ) : (
    <></>
  );
};
export default ContactDetails;
