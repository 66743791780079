import React from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';

import styles from './styles.module.scss';
import FilesInput from '../FilesInput';
import BaseButton from 'components/UI/Buttons/BaseButton';
import useFilesDnDViewModel from './useFilesDnDViewModel';

const DragAndDropArea: React.FC = () => {
  const { t } = useTranslation('files');
  const {
    onAddButtonClick,
    handleDrop,
    handleDrag,
    formattedInstructionText,
    hiddenInputRef,
    isDragActive,
  } = useFilesDnDViewModel();

  return (
    <>
      <div
        className={clsx(styles.Area, isDragActive && styles.DragActive)}
        onClick={onAddButtonClick}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {!isDragActive && (
          <div
            className={styles.TextInstruction}
            dangerouslySetInnerHTML={{ __html: formattedInstructionText }}
          />
        )}
        <div className={clsx(styles.DnDAreaDescription, isDragActive && styles.DragDescription)}>
          {isDragActive ? t('dnd_area.drag_text') : t('dnd_area.file_size_limit_text')}
        </div>
      </div>
      <BaseButton variant={'text'} className={styles.AddButton} onClick={onAddButtonClick}>
        <div>+ {t('add_file_icon_text')}</div>
      </BaseButton>
      <FilesInput ref={hiddenInputRef} hidden />
    </>
  );
};

export default DragAndDropArea;
