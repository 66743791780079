import { useTranslation } from 'next-i18next';

import MenuItem from '@material-ui/core/MenuItem';

import ImportBlueIcon from 'icons/import-blue.svg';

import CSVInput from '../CSVInput';

const ImportColumnButton = ({
  inputId,
  className,
  props,
  onClick,
}: {
  inputId: string;
  className?: string;
  props?: {
    tagName?: string;
    columnId?: string;
  };
  onClick?: (e) => void;
}) => {
  const { t } = useTranslation('contacts-page');
  return (
    <label htmlFor={`file_${inputId}`}>
      <MenuItem onClick={onClick} className={className}>
        <CSVInput inputProps={props} inputId={inputId} />
        <ImportBlueIcon />
        {t('common:import')}
      </MenuItem>
    </label>
  );
};
export default ImportColumnButton;
