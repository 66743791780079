import { Popover } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { Button } from '@material-ui/core';

import PhonesInput from './PhonesInput';
import EmailsInput from './EmailsInput';
import AddressesInput from './AddressesInput';
import WebInput from './WebsitesInput';
import EventInput from './EventsInput';
import BaseButton from '../../../components/UI/Buttons/BaseButton';
import styles from 'styles/contactDetails/contactDetails.module.scss';
import { setField, updateContactById } from 'slices/contactsSlice';
import { FormValues } from '../../addContact';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { selectCurrentContact } from 'slices/contactsSlice';
import { FormTypes, FormArrayNames, getDefaultValues, getFormResolver } from './formData';
import { devLogError } from 'src/utils/logger';

interface IProps {
  open: boolean;
  setOpen: (v: boolean) => void;
  anchorEl: HTMLElement;
  type: string;
  isWithoutPhoneNumber?: boolean;
}

const ContactFieldsForm: React.FC<IProps> = ({
  open,
  setOpen,
  anchorEl,
  type,
  isWithoutPhoneNumber,
}) => {
  const { t } = useTranslation('add-contact-page');
  const dispatch = useAppDispatch();
  const currentContact = useAppSelector(selectCurrentContact);
  const id = open ? `${type}-edit-form` : undefined;
  const actionRef = useRef(null);
  const defaultValues = getDefaultValues(currentContact);
  const methods = useForm<Omit<FormValues, 'fullName' | 'jobTitle' | 'company'>>({
    defaultValues,
    resolver: getFormResolver(currentContact.suggestion_name || currentContact.name),
  });
  const { getValues, reset, watch, handleSubmit } = methods;
  const watchAllFields = watch();

  useEffect(() => {
    actionRef?.current?.updatePosition();
  }, [watchAllFields]);

  const handleClose = () => {
    reset(defaultValues);
    setOpen(false);
  };

  const onSubmit = () => {
    const { phones, emails, urls, addresses, dates } = getValues();
    const parsedDates = dates.map(({ event, type }) => ({
      day: event?.getDate(),
      month: event?.getMonth(),
      year: event?.getFullYear(),
      type,
    }));

    const parsedPhones = phones.map(({ number, type }) => ({
      type,
      normalized_phone: number,
      custom_label: null,
      phone: number,
    }));

    const parsedEmails = emails
      .filter(({ email }) => email.length)
      .map(({ email }) => ({
        email,
        custom_label: null,
        type,
      }));

    const parsedAddresses = addresses
      .filter(({ address }) => address?.length)
      .map(({ address, type }) => ({
        full_address: address,
        type,
      }));

    const parsedUrls = urls
      ?.filter(({ url }) => url.length)
      .map(({ url }) => ({
        url,
      }));

    switch (type) {
      case FormTypes.PHONE:
        dispatch(setField({ type: FormArrayNames.PHONES, value: parsedPhones }));
        break;
      case FormTypes.EMAIL:
        dispatch(setField({ type: FormArrayNames.EMAILS, value: parsedEmails }));
        break;
      case FormTypes.URL:
        dispatch(setField({ type: FormArrayNames.URLS, value: parsedUrls }));
        break;
      case FormTypes.ADDRESS:
        dispatch(setField({ type: FormArrayNames.ADDRESSES, value: parsedAddresses }));
        break;
      case FormTypes.EVENT:
        dispatch(setField({ type: FormArrayNames.DATES, value: parsedDates }));
        break;
      default:
        return;
    }

    dispatch(updateContactById(currentContact.uuid));
    setOpen(false);
  };

  return (
    <Popover
      PaperProps={{
        classes: { rounded: styles.contactFieldsFormWrapper },
      }}
      action={actionRef}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: -30,
        horizontal: 'center',
      }}
    >
      <div className={styles.contactFieldsForm}>
        <FormProvider {...methods}>
          <form
            onSubmit={handleSubmit(onSubmit, (e) => devLogError('Contact Details form error: ', e))}
          >
            {type === FormTypes.PHONE && (
              <PhonesInput isWithoutPhoneNumber={isWithoutPhoneNumber} />
            )}
            {type === FormTypes.EMAIL && <EmailsInput />}
            {type === FormTypes.URL && <WebInput />}
            {type === FormTypes.ADDRESS && <AddressesInput />}
            {type === FormTypes.EVENT && (
              <EventInput updateView={actionRef?.current?.updatePosition} />
            )}
            <div className={styles.contactFieldsFormActions}>
              <BaseButton type="submit" variant="contained">
                {t('common:save')}
              </BaseButton>
              <Button onClick={handleClose} className={styles.contactFieldsFormActionsCancel}>
                {t('duplicates:Cancel_action')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Popover>
  );
};
export default ContactFieldsForm;
