import { ChangeEventHandler, useContext } from 'react';

import FilesContext from '../../../domain/context/FilesContext';

const useFilesInputViewModel = (onFilesAddedToQueue?: () => void) => {
  const { addItemToQueue } = useContext(FilesContext);

  const handleInputFilesSelect: ChangeEventHandler<HTMLInputElement> = ({ target: { files } }) => {
    addItemToQueue(Array.prototype.slice.call(files));

    if (onFilesAddedToQueue) {
      onFilesAddedToQueue();
    }
  };

  return {
    handleInputFilesSelect,
  };
};

export default useFilesInputViewModel;
