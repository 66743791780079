import { useState, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import clsx from 'clsx';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';
import add from 'date-fns/add';

import { calcTotal, calcSubtotal, calcTax } from 'src/slices/proposalsSlice';

import { useProposalDrawer } from '../../../../../proposals/ProposalDrawer';
import RemoveIcon from 'public/images/icons/remove.svg';
import DownloadIcon from 'public/images/icons/download.svg';
import EditIcon from 'public/images/icons/edit.svg';
import CopyIcon from 'public/images/icons/copy.svg';
import ClockIcon from 'public/images/icons/clock.svg';
import ExpandIcon from 'public/images/icons/expand.svg';
import styles from './ProposalItem.module.css';
import useDownloadProposal from './useDownloadProposal';
import useDeleteProposal from './useDeleteProposal';

import Spinner from 'components/UI/Spinner';
import DeleteDialog from '../../../../../../components/UI/Dialogs/DeleteDialog';
import Tooltip from 'components/UI/Tooltip';
import ProposalEntity from 'src/db/entities/proposal/ProposalEntity';
import DirectedText from '../../../../../../features2/localization/ui/DirectedText';
import { useQuotesLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

export default function ProposalItem(props: ProposalEntity) {
  const {
    uuid,
    number,
    createdAt,
    title,
    items,
    sentAt,
    isDraft,
    updatedAt,
    settings,
    discountAmount,
    discountType,
  } = props;
  const { checkLimits } = useQuotesLimits();

  const { currency, validity, includesTax, taxRate } = settings;

  const {
    t,
    i18n: { language },
  } = useTranslation('contact-details');

  const { openUpdateForm, openDuplicateForm, close } = useProposalDrawer();

  const hiddenContentRef = useRef<HTMLDivElement>();

  const [hiddenContentHeight, setHiddenContentHeight] = useState<number | null>(null);
  const isExpanded = !!hiddenContentHeight;

  const [download, isGenerating] = useDownloadProposal();
  const [deleteProposal, isDeleting] = useDeleteProposal();

  const expand = () => {
    setHiddenContentHeight(hiddenContentRef.current.offsetHeight);
  };

  const toggle = () => {
    if (hiddenContentHeight) {
      setHiddenContentHeight(null);
    } else {
      expand();
    }
  };

  const formatCurrency = (value: number) => {
    return value.toLocaleString(language, {
      style: 'currency',
      currency: currency,
      currencyDisplay: 'code',
    });
  };

  const { total } = calcTotal({
    items,
    includesTax: includesTax,
    taxRate: taxRate,
    discountAmount,
    discountType,
  });

  const totalFormatted = formatCurrency(total);

  const sentAtDate = startOfDay(new Date(sentAt || updatedAt));
  const validUntil = add(sentAtDate, { days: validity });

  const subtotal = calcSubtotal(items);
  const subtotalFormatted = formatCurrency(calcSubtotal(items));
  const taxFormatted = includesTax ? formatCurrency(calcTax(subtotal, taxRate)) : 0;

  const handleEditButtonClick = () => {
    openUpdateForm({ uuid, onSuccess: close });
  };

  const handleDuplicateButtonClick = () => {
    checkLimits() && openDuplicateForm({ uuid, onSuccess: close });
  };

  const handleDownloadButtonClick = () => {
    download(props);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDeleteButtonClick = () => {
    setDialogOpen(true);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <button
          className={clsx(styles.expandMore, isExpanded && styles.isExpanded)}
          onClick={toggle}
        >
          <ExpandIcon />
        </button>
        <div className={styles.number}>#{number}</div>
        <div className={styles.createdAt}>{format(new Date(createdAt), 'dd.MM.yyyy')}</div>
        <div className={styles.title}>
          {title} {isDraft && <div className={styles.draft}>{t('proposals.item.draft')}</div>}
        </div>
        <div className={styles.total}>
          <DirectedText>
            <span style={{ color: 'var(--secondary-text-color)' }}>
              {t('proposals.item.total')}
            </span>{' '}
            {totalFormatted}
          </DirectedText>
        </div>
      </div>
      <div
        className={clsx(styles.items, isExpanded && styles.visible)}
        style={{ maxHeight: hiddenContentHeight || 0 }}
      >
        <div className={styles.itemsContent} ref={hiddenContentRef}>
          <table>
            <thead>
              <tr>
                <th colSpan={2}>{t('proposals.item.items')}</th>
                <th>{t('proposals.item.qty')}</th>
                <th>{t('proposals.item.unitPrice')}</th>
                <th>{t('proposals.item.amount')}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={item.description}>
                  <td>#{index + 1}</td>
                  <td>{item.description}</td>
                  <td>{item.qty}</td>
                  <td>{formatCurrency(item.unitPrice)}</td>
                  <td>{formatCurrency(item.unitPrice * item.qty)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={styles.totals}>
            <div>
              <span>{t('proposals.item.subtotal')}</span> {subtotalFormatted}
            </div>
            <div>
              <span>{t('proposals.item.tax')}</span>{' '}
              {includesTax ? taxFormatted : t('proposals.item.withoutTax')}
            </div>
            <div>
              <span>{t('proposals.item.total')}</span> {totalFormatted}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomContent}>
          <div className={styles.validity}>
            <span>
              <ClockIcon />
            </span>
            {t('proposals.item.validity')} {format(validUntil, 'dd.MM.yyyy')}
          </div>
          <div className={styles.actions}>
            <Tooltip placement={'top'} title={t('duplicates:Edit_action')}>
              <button
                className={clsx(styles.actionButton, styles.editAction)}
                onClick={handleEditButtonClick}
              >
                <EditIcon className={styles.buttonIcon} />
              </button>
            </Tooltip>
            <Tooltip placement={'top'} title={t('tooltips.copy')}>
              <button
                className={clsx(styles.actionButton, styles.duplicateAction)}
                onClick={handleDuplicateButtonClick}
              >
                <CopyIcon className={styles.buttonIcon} />
              </button>
            </Tooltip>

            <Tooltip placement={'top'} title={t('tooltips.download')}>
              <button
                className={clsx(styles.actionButton, styles.downloadAction)}
                onClick={handleDownloadButtonClick}
                disabled={isGenerating}
              >
                {isGenerating ? (
                  <Spinner size={16} className={styles.buttonSpinner} />
                ) : (
                  <DownloadIcon className={styles.buttonIcon} />
                )}
              </button>
            </Tooltip>
            <Tooltip placement={'top'} title={t('duplicates:Delete_action')}>
              <button
                className={clsx(styles.actionButton, styles.deleteAction)}
                onClick={handleDeleteButtonClick}
                disabled={isDeleting}
              >
                {isDeleting ? (
                  <Spinner size={16} className={styles.buttonSpinner} />
                ) : (
                  <RemoveIcon className={styles.buttonIcon} />
                )}
              </button>
            </Tooltip>
            <DeleteDialog
              open={dialogOpen}
              title={t('proposals.deleteDialog.title')}
              description={t('proposals.deleteDialog.description')}
              onClose={() => setDialogOpen(false)}
              onSuccess={() => deleteProposal(uuid)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
