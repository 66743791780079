import { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';

import ChooseColorIcon from '../../../../public/images/icons/choose-color.svg';
import Palette from './Palette';
import styles from './PaletteForm.module.css';

const PalleteColor = ({
  defaultColor,
  onColorPick,
  popperZindex,
}: {
  defaultColor: string;
  onColorPick: (color: string) => void;
  popperZindex?: number;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [color] = useState(defaultColor);
  const [arrowRef, setArrowRef] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const onSelectColor = (color) => {
    onColorPick(color);
    setOpen(false);
  };

  return (
    <>
      <IconButton className={styles.colorIconButton} onClick={handleClick}>
        <ChooseColorIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={'bottom-end'}
        transition
        modifiers={{
          arrow: {
            enabled: true,
            element: arrowRef,
          },
        }}
        style={{ zIndex: popperZindex || 1500 }}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper className={styles.colorWrapper}>
              <span ref={setArrowRef}></span>

              <Palette color={color} onPick={onSelectColor} />
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};

export default PalleteColor;
