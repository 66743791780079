import { useTranslation } from 'next-i18next';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ChooseColorIcon from '../../../../public/images/icons/choose-color.svg';
import { itemColors } from '../../../utils/constants';

import styles from './PaletteForm.module.css';

export default function Palette({
  color,
  onPick,
  className,
}: {
  color: string;
  onPick: (color: string) => void;
  className?: string;
}) {
  const { t } = useTranslation('common');

  return (
    <div className={className}>
      <div className={styles.title}>
        <ChooseColorIcon />
        {t('colors')}
      </div>
      <div className={styles.colorPalette}>
        {itemColors.map((itemColor) => {
          return (
            <button
              key={itemColor}
              className={styles.colorPaletteItem}
              style={{ backgroundColor: itemColor }}
              onClick={() => onPick(itemColor)}
            >
              {itemColor === color && <CheckOutlinedIcon />}
            </button>
          );
        })}
      </div>
    </div>
  );
}
