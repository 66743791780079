import { useAppSelector } from 'src/app/hooks';
import { selectCurrentContact } from 'slices/contactsSlice';
import { useTranslation } from 'next-i18next';

import styles from './Tasks.module.css';

import { selectTasksByContactId } from '../../../../../slices/tasksSlice/tasksSlice';
import { sortByDueDate } from '../../../../../slices/tasksSlice/utils';

import Section from '../Section';
import useTaskDrawer from 'src/features/tasks/TaskDrawer/useTaskDrawer';
import TaskItem from './TaskItem';

import { useTasksLimits } from 'src/features2/billing/ui/limitsAndOverlays/limits/useLimits';

const Tasks = () => {
  const { t } = useTranslation('contact-details');
  const { checkLimits } = useTasksLimits();

  const currentContact = useAppSelector(selectCurrentContact);
  const tasks = useAppSelector((state) => selectTasksByContactId(state, currentContact.uuid));
  const sortedTasks = sortByDueDate(tasks);

  const { openCreateForm } = useTaskDrawer();

  return (
    <Section
      title={t('duplicates:Tasks_title')}
      entityName={t('tasks.entity')}
      entitiesNumber={sortedTasks.length}
      onClickCreate={() => {
        checkLimits() && openCreateForm({ defaultValues: { contactId: currentContact.uuid } });
      }}
    >
      {(expanded) => {
        const visibleTasks = expanded ? sortedTasks : sortedTasks.slice(0, 2);
        return (
          <div className={styles.listContainer}>
            {visibleTasks.map((task) => (
              <TaskItem key={task.id} task={task} />
            ))}
          </div>
        );
      }}
    </Section>
  );
};
export default Tasks;
