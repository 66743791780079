import { useTranslation } from 'next-i18next';
import { useFormContext, Controller, useFieldArray } from 'react-hook-form';
import clsx from 'clsx';

import { FormValues } from '../types';
import Select from 'components/UI/Inputs/Select';
import TextField from 'components/UI/Inputs/TextField';
import styles from 'styles/addContact/addContacts.module.css';
import styles2 from 'styles/contactDetails/contactDetails.module.scss';
import Button from 'components/UI/Buttons/BaseButton';
import { FormArrayNames, FormTypes, AddressFormSelectOptions, getFieldName } from './formData';

export default function AddressesInput() {
  const { t } = useTranslation('add-contact-page');
  const { control } = useFormContext<FormValues>();
  const { fields, append, remove } = useFieldArray<FormValues, FormArrayNames.ADDRESSES>({
    control,
    name: FormArrayNames.ADDRESSES,
  });

  return (
    <div>
      {fields.map((item, index) => {
        return (
          <div key={item.id}>
            <Controller
              name={getFieldName(FormTypes.ADDRESS, index, true)}
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  classes={{ root: styles2.formSelect }}
                  menuItemClassName={styles2.formSelectItem}
                  variant="standard"
                  options={[
                    { value: AddressFormSelectOptions.WORK, text: t('work') },
                    { value: AddressFormSelectOptions.HOME, text: t('home') },
                    { value: AddressFormSelectOptions.OTHER, text: t('duplicates:Other_label') },
                  ]}
                />
              )}
            />
            <Controller
              name={getFieldName(FormTypes.ADDRESS, index)}
              control={control}
              render={({ field, fieldState }) => (
                <div className={styles2.formField}>
                  <TextField
                    {...field}
                    className={styles2.contactFormTitle}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    placeholder={t('addressHint')}
                    fullWidth
                    margin="dense"
                  />
                  <Button
                    className={clsx(styles2.formDeleteButton)}
                    variant="text"
                    onClick={() => {
                      remove(index);
                      index === 0 &&
                        fields.length == 1 &&
                        append({ full_address: '', type: AddressFormSelectOptions.WORK });
                    }}
                  >
                    {t('duplicates:Delete_action')}
                  </Button>
                </div>
              )}
            />
          </div>
        );
      })}
      <button
        className={styles.addFieldButton}
        type="button"
        onClick={() => {
          append({ type: AddressFormSelectOptions.WORK, full_address: '' });
        }}
      >
        {t('addAnotherAddress')}
      </button>
    </div>
  );
}
