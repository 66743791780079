import clsx from 'clsx';
import styles from './ContactCard.module.css';
import Details from './components/Details';
import Actions from './components/Actions';
import AssignToSection from './components/AssignToSection';

export default function ContactCard(
  props: {
    children: React.ReactNode;
  } & React.HTMLAttributes<HTMLDivElement>
) {
  const { children, className, ...rest } = props;

  return (
    <div className={clsx(styles.wrapper, className)} {...rest}>
      {children}
    </div>
  );
}

ContactCard.Details = Details;
ContactCard.Actions = Actions;
ContactCard.AssignToSection = AssignToSection;
