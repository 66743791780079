import { useContext } from 'react';

import ImportCSVContext from '../../ImportCSVContext';

const useCSVInputViewModel = (props: { tagName?: string; columnId?: string }) => {
  const { handleLoadFile } = useContext(ImportCSVContext);

  const handleInputCSVSelect = (file: File) => {
    handleLoadFile({ file, ...props });
  };

  return {
    handleInputCSVSelect,
  };
};

export default useCSVInputViewModel;
