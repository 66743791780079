import { useLayoutEffect } from 'react';
import { ItemModel } from 'src/api/boardAPI';
import { TagItem } from 'src/api/tagsBoardAPI';

const setStyles = (element: HTMLDivElement, index: number, isBoardEmpty?: boolean) => {
  const children = Array.from(element.children) as HTMLDivElement[];

  const setStyleAttribute = (height: number, width: number) => {
    element.setAttribute(
      'style',
      `
    --mask-height: ${height}px;
    --mask-width: ${width}px;
    width: 100%;
    `
    );
  };

  if (isBoardEmpty) {
    setStyleAttribute(400, element.offsetWidth);
    element.dataset.isInit = 'true';
  } else {
    if (children.length) {
      let commonHeight = 61 + children.length * 10;
      children.forEach((ch) => {
        if (ch.dataset.rbdDraggableContextId || ch.dataset.emptyState) {
          commonHeight += ch.offsetHeight;
        }
      });

      setStyleAttribute(commonHeight, element.offsetWidth);
    } else {
      setStyleAttribute(117, element.parentElement.parentElement.offsetWidth);
    }
  }

  element.dataset.isInit = 'true';
};

const useSetColumnStyles = (columnItems: TagItem[] | ItemModel[], isBoardEmpty?: boolean) => {
  useLayoutEffect(() => {
    const elements = Array.from(
      document.querySelectorAll<HTMLDivElement>(`div[data-element-type='drop-wrapper']`)
    );

    elements.forEach((element, index) => {
      setStyles(element, index, isBoardEmpty);

      const observerConfig = { childList: true };

      const observer = new MutationObserver((mutationList) => {
        for (const mutation of mutationList) {
          if (mutation.type === 'childList') {
            setStyles(element, index);
          }
        }
      });

      observer.observe(element, observerConfig);
    });
  }, [columnItems]);
};

export default useSetColumnStyles;
