import Divider from '@material-ui/core/Divider';
import clsx from 'clsx';
import { useTranslation } from 'next-i18next';

import {
  selectCurrentContact,
  selectRelatedContactsByPhones,
  selectRelatedContactsByEmails,
} from 'slices/contactsSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { setCurrentContact } from 'slices/contactsSlice';
import ContactCard from 'components/UI/ContactCard';
import baseStyles from 'styles/calls/board.module.css';
import styles from './styles/relatedContact.module.scss';
import useFormatNumber from '../../app/hooks/ui/useFormatNumber';
import Contact from '../../../types/contact';
import contactService from '../../services/contacts/contactService';

const SimilarContacts = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('contact-details');
  const currentContact = useAppSelector(selectCurrentContact);
  const currentContactPhones = currentContact?.phones?.map((phone) => phone.normalized_phone);
  const currentContactEmails = currentContact?.emails?.map((email) => email.email);
  const { formatNumber } = useFormatNumber();
  const contactPhone = currentContact?.phones?.[0]?.phone;
  const contactEmail = currentContact?.emails?.[0]?.email;
  const isWithoutNumber = contactPhone === '' || contactPhone === undefined;
  const relatedContactsByPhones = useAppSelector(
    selectRelatedContactsByPhones(currentContactPhones)
  );
  const relatedContactsByEmails = useAppSelector(
    selectRelatedContactsByEmails(currentContactEmails)
  );

  const filterRelatedContacts = (contact: Contact) => {
    const isAnchor = (currentContact.anchor_contact_ids || []).includes(contact.uuid);
    return !isAnchor && contact.uuid !== currentContact.uuid;
  };

  const relatedContactsList = [];
  if (contactPhone) {
    relatedContactsList.concat(relatedContactsByPhones.filter(filterRelatedContacts));
  } else if (isWithoutNumber && !!contactEmail) {
    relatedContactsList.concat(relatedContactsByEmails.filter(filterRelatedContacts));
  }

  return (
    !!relatedContactsList.length && (
      <div className={styles.wrapper}>
        <Divider />
        <h3 className={styles.title}>
          {t(`relatedContacts${relatedContactsList.length === 1 ? '_1' : ''}`, {
            count: relatedContactsList.length,
          })}
        </h3>
        {relatedContactsList.map((contact) => {
          const itemPhoneNumber = contact?.phones?.[0]?.phone;
          const formattedPhoneNumber = itemPhoneNumber
            ? formatNumber(itemPhoneNumber, contact.phones?.[0]?.normalized_phone)
            : '';

          return (
            <ContactCard
              onClick={() => {
                dispatch(setCurrentContact(contact.uuid));
              }}
              key={contact.uuid}
              className={clsx(baseStyles.ContactCard, styles.relatedContactWrapper)}
            >
              <ContactCard.Details
                name={contactService.getContactName(contact)}
                thumbnail={contactService.getContactName(contact)}
                phoneNumber={formattedPhoneNumber}
                jobTitle={contact.suggestion_job_title || contact.job_title}
                badges={[]}
                isSpammer={contact.is_spammer}
              />
            </ContactCard>
          );
        })}
      </div>
    )
  );
};
export default SimilarContacts;
