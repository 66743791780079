import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';

import Section from '../Section';
import FilesDnD from '../../../../../features2/files/ui/Files/FilesDnD';
import Contact from '../../../../../../types/contact';
import FilesList from '../../../../../features2/files/ui/Files/FilesList';
import useWindowResizeListener from '../../../../../app/hooks/ui/useWindowResizeListener';

interface IFilesProps {
  contactId: Contact['uuid'];
}

const Files: React.FC<IFilesProps> = ({ contactId }) => {
  const { t } = useTranslation('contact-details');
  const [countToExpand, setCountToExpand] = useState(5);

  useWindowResizeListener(() => {
    const windowWidth = window.innerWidth;

    if (windowWidth < 1507) {
      setCountToExpand(1);
    } else if (windowWidth < 1810) {
      setCountToExpand(2);
    } else if (windowWidth < 2030) {
      setCountToExpand(3);
    } else {
      setCountToExpand(5);
    }
  });

  return (
    <Section
      withButton={false}
      title={t('files.title')}
      entityName={t('files.entity')}
      onClickCreate={() => {}}
      entitiesCountToExpand={countToExpand}
    >
      {(expanded, setFilesCount) => {
        return (
          <>
            <FilesList
              currentContactUuid={contactId}
              isExpanded={expanded}
              setFilesCount={setFilesCount}
            />
            <FilesDnD />
          </>
        );
      }}
    </Section>
  );
};

export default Files;
