import React from 'react';
import { Popover } from '@material-ui/core';
import { useTranslation } from 'next-i18next';

import FileBC from '../../../domain/FileBC';
import styles from './styles.module.scss';
import EditIcon from 'icons/edit.svg';
import RemoveIcon from 'icons/remove.svg';
import DownloadIcon from 'icons/download.svg';
import useFileListItemViewModel from './useFileListItemViewModel';
import FileInfo from './components/FileInfo';

interface IFileListProps {
  file: FileBC;
}

const FileListItem: React.FC<IFileListProps> = ({ file }) => {
  const { t } = useTranslation('files');
  const {
    onDeleteFile,
    onDownloadFile,
    onRenameDialog,
    onFileClick,
    onCloseFileMenu,
    onMoreButtonClick,
    fileMenuAnchorEl,
  } = useFileListItemViewModel(file);

  return (
    <div className={styles.Item}>
      <FileInfo file={file} onFileClick={onFileClick} />
      <div className={styles.Actions}>
        <button className={styles.MoreButton} onClick={onMoreButtonClick}>
          <div />
          <div />
          <div />
        </button>
        <Popover
          open={!!fileMenuAnchorEl}
          anchorEl={fileMenuAnchorEl}
          classes={{ paper: styles.FilesMenu }}
          onClose={onCloseFileMenu}
        >
          <button className={styles.MenuButton} onClick={onRenameDialog}>
            <EditIcon />
            <span>{t('file_action_rename')}</span>
          </button>
          <button className={styles.MenuButton} onClick={onDownloadFile}>
            <DownloadIcon />
            <span>{t('file_action_download')}</span>
          </button>
          <button className={styles.MenuButton} onClick={onDeleteFile}>
            <RemoveIcon style={{ fill: 'var(--color-radical-red)' }} />
            <span>{t('file_action_delete')}</span>
          </button>
        </Popover>
      </div>
    </div>
  );
};

export default FileListItem;
