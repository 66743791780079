import React, { useState, MouseEvent, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import MoveToModal from 'components/Modals/MoveToModal';
import AssignToSelect from '../../../features/collaboration/components/AssignToSelect/AssignToSelect';
import useAssignContact from '../../../features/collaboration/hooks/useAssignContact';
import useDuplicateContact from '../../../features/contacts/hooks/useDuplicateContact';
import { hideCallsBatch } from 'slices/callsSlice';
import DownArrowIcon from 'icons/down-arrow.svg';
import clsx from 'clsx';
import styles from './styles.module.scss';
import { useTranslation } from 'next-i18next';
import { selectCurrentContact } from 'slices/contactsSlice';
import { selectBoard, selectBoardContactsMap } from 'slices/boardSlice';
import {
  selectIsCurrentWorkspaceCollaborative,
  selectAcceptedTeamMembers,
} from 'slices/workspacesSlice';
import { makeSelectProposalsByContact } from 'slices/proposalsSlice';
import useProposalDrawer from '../../../features/proposals/ProposalDrawer/useProposalDrawer';
import Index from '../../../features2/localization/ui/DirectedText';

interface IProps {
  reportData: any;
}

const ActionSelects: React.FC<IProps> = ({ reportData }) => {
  const { openCreateForm, close } = useProposalDrawer();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('common');
  const contact = useAppSelector(selectCurrentContact);
  const dispatch = useAppDispatch();
  const selectProposalsByContact = useMemo(makeSelectProposalsByContact, []);
  const proposals = useAppSelector((state) => selectProposalsByContact(state, contact.uuid));
  const hasRelatedProposals = proposals.some(({ visible }) => visible);
  const board = useAppSelector(selectBoard);
  const assignContact = useAssignContact('Contact Details');
  const duplicateContact = useDuplicateContact();
  const isCurrentWorkspaceCollaborative = useAppSelector(selectIsCurrentWorkspaceCollaborative);
  const options = useAppSelector(selectAcceptedTeamMembers);
  const boardContactMap = useAppSelector(selectBoardContactsMap);
  const contactStatusTitle = board.columns.find(
    // @ts-ignore
    (column) => column.id === boardContactMap[contact.uuid]?.columnUuid
  )?.title;

  if (reportData) return null;

  const getStatus = () => {
    if (contact?.is_archived) {
      return t('tags.archive');
    } else if (hasRelatedProposals) {
      return t('proposals:sectionName');
    } else {
      return contactStatusTitle;
    }
  };

  const contactStatusTitleResult = getStatus();

  let currentCard;

  board.columns.forEach(({ items }) => {
    if (currentCard) return;
    currentCard = items.find(({ typeUuid }) => typeUuid === contact.uuid);
  });

  const handleOpenMoveToModal = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleCloseMoveToModal = () => {
    setAnchorEl(null);
  };

  const handleSendProposal = () => {
    const handleSuccessSendProposal = async () => {
      const contactPhones = contact.phones.map(({ normalized_phone }) => normalized_phone);
      dispatch(hideCallsBatch(contactPhones));
      close();
    };
    openCreateForm({
      defaultValues: { contactId: contact.uuid },
      onSuccess: handleSuccessSendProposal,
      type: 'Move to',
    });
  };

  const handleSelect = async (email: string) => {
    if (contact.workspace_id === null) {
      await duplicateContact(contact.uuid, {
        assigned_to: email,
      });
    } else {
      assignContact(contact.uuid, email);
    }
  };

  return (
    <div className={styles.selectsWrapper}>
      <button className={styles.statusButton} onClick={handleOpenMoveToModal}>
        <Index>
          {contactStatusTitleResult || t('duplicates:Status_title')}
          <DownArrowIcon className={clsx(styles.buttonIconArrow, !!anchorEl && styles.menuOpen)} />
        </Index>
      </button>
      {isCurrentWorkspaceCollaborative && (
        <AssignToSelect
          className={styles.AssignSelect}
          options={options}
          value={contact.assigned_to}
          onSelect={handleSelect}
        />
      )}

      <MoveToModal
        action={'Contact details'}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        columnUuid={currentCard?.columnUuid}
        itemUuid={currentCard?.uuid}
        contactUuid={contact.uuid}
        anchorEl={anchorEl}
        onClose={handleCloseMoveToModal}
        proposalSelected={hasRelatedProposals}
        onSendProposal={handleSendProposal}
      />
    </div>
  );
};
export default ActionSelects;
