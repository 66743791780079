import { useMemo, useEffect } from 'react';
import { useTranslation } from 'next-i18next';

import proposalsStyles from './proposals.module.css';
import { useAppSelector } from '../../../../../app/hooks';
import { selectCurrentContact } from 'slices/contactsSlice';
import { makeSelectProposalsByContact } from 'slices/proposalsSlice';
import ProposalItem from './ProposalItem/ProposalItem';
import { useProposalDrawer } from 'src/features/proposals/ProposalDrawer';
import Section from '../Section';
import useRedirectEffect from 'src/app/hooks/ui/useRedirectEffect';
import { TRedirectEffect } from 'types/ui';
import RedirectEffectTargetType from 'src/enums/RedirectEffectTargetType';
import { useDispatch } from 'react-redux';
import { setRedirectEffect } from 'slices/uiSlice';
import { trackEvent } from 'src/analytics/amplitude';

export default function Proposals() {
  const { t } = useTranslation('contact-details');

  const currentContact = useAppSelector(selectCurrentContact);
  const selectProposalsByContact = useMemo(makeSelectProposalsByContact, []);
  const proposals = useAppSelector((state) => selectProposalsByContact(state, currentContact.uuid));
  const { openCreateForm, openUpdateForm, close } = useProposalDrawer();
  const redirectEffect = useRedirectEffect(RedirectEffectTargetType.NOTE) as TRedirectEffect;
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirectEffect && redirectEffect.targetType === RedirectEffectTargetType.QUOTE) {
      openUpdateForm({
        uuid: redirectEffect.targetId,
        onSuccess: close,
      });
      dispatch(setRedirectEffect(null));
    }
  }, [redirectEffect, close, openUpdateForm, dispatch]);

  const handleClickAdd = () => {
    trackEvent('Click_add_quote');

    openCreateForm({
      type: 'Contact details',
      defaultValues: { contactId: currentContact.uuid },
      onSuccess: close,
      shouldPinContact: true,
    });
  };

  return (
    <Section
      title={t('proposals.title')}
      entityName={t('proposals.entity')}
      entitiesNumber={proposals.length}
      onClickCreate={handleClickAdd}
    >
      {(expanded) => {
        const visibleProposals = expanded ? proposals : proposals.slice(0, 2);
        return (
          visibleProposals.length > 0 && (
            <ul className={proposalsStyles.list}>
              {visibleProposals.map((proposalItem) => (
                <li key={proposalItem.uuid} className={proposalsStyles.listItem}>
                  <ProposalItem {...proposalItem} />
                </li>
              ))}
            </ul>
          )
        );
      }}
    </Section>
  );
}
